import React, { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Box, Grid } from "@mui/material";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import { FaCrosshairs } from "react-icons/fa";
import { DatePicker, Space } from "antd";
import "antd/dist/antd.css";
import { hotels } from "../db";
import "react-select-search/style.css";
import moment from "moment";
import "moment/locale/en-au";
import { useDispatch, useSelector } from "react-redux";
import {
  setCity,
  setStartDate,
  setEndDate,
  setRooms,
  setGuests,
} from "../../store/slice/searchslice";
import AutoComplete from "./AutoComplete";

function SearchField() {
  const { RangePicker } = DatePicker;

  const history = useHistory();
  const [roomshow, setrooms] = useState(false);
  const dispatch = useDispatch();
  const { city, cityList, startDate, endDate, rooms, guests } = useSelector(
    (state) => state.searchslice
  );

  const handleRoomDIv = () => {
    setrooms(true);
  };
  // console.log(startDate);
  let roomRef = useRef();

  const handleLinkClick = () => {
    history.push(`/hotels?location=${city}`);
  };

  useEffect(() => {
    let handler = (event) => {
      if (roomRef.current && !roomRef.current.contains(event.target)) {
        setrooms(false);
      }
    };

    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  const handleOnSelect = (itemData) => {
    let data = cityList?.find((item) => item?.area === itemData);
    dispatch(setCity(data?.id));
  };

  const formatResult = (item) => {
    return (
      <>
        <span style={{ display: "block", textAlign: "left" }}>{item.city}</span>
      </>
    );
  };

  const handleNearMe = () => {
    // Handle "Near Me" logic here
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          // Perform location-based logic here
          console.log("Latitude:", position.coords.latitude);
          console.log("Longitude:", position.coords.longitude);
          dispatch(setCity("Delhi"));
        },
        (error) => {
          console.error("Error getting location:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported");
    }
  };

  const handleDateChange = (dates) => {
    alert(dates);
    // dispatch(setStartDate(dates[0]));
    // dispatch(setEndDate(dates[1]));
  };
  console.log(startDate);

  const disabledDate = (current) => {
    // Disable dates before today and after one month from today
    return current && current < moment().startOf("day");
  };

  return (
    <>
      <div className="red-rectangle  container border shadow-lg bg-white">
        {/* <img src="/Images/redRectangle.png" alt="redRectangle" /> */}
        <div className="search-field  text-center" style={{}}>
          <Box
            sx={{
              width: ["100%", "30%"],
              height: "50%",
              position: "relative",
              marginRight: 2,
            }}
          >
            <AutoComplete onChangevalue={handleOnSelect} />
            {/* <ReactSearchAutocomplete
              items={hotels.hotel}
              value={city}
              onChange={handleOnSelect}
              formatResult={formatResult}
              styling={{ borderRadius: "10px", height: "3px" }}
              placeholder="City"
              className="input-city"
            /> */}
            {/* <button
              onClick={handleNearMe}
              style={{ right: 10, borderRadius: 10, top: "25%" }}
              className="btn  btn-badge btn-light btn-sm position-absolute"
            >
              <FaCrosshairs /> Near Me
            </button> */}
          </Box>
          <Space
            direction="horizontal"
            style={{ height: "100px", position: "relative" }}
          >
            <DatePicker
              value={startDate}
              onChange={(date) => dispatch(setStartDate(date))}
              format="DD-MM-YYYY"
              placeholder={"Check In"}
              className="text-dark"
              style={{
                fontWeight: "bolder",
                height: "45px",
              }}
              mode="date"
            />
            <DatePicker
              value={endDate}
              onChange={(date) => dispatch(setEndDate(date))}
              format="DD-MM-YYYY"
              placeholder={"Check Out"}
              className="text-dark"
              mode="date"
              style={{
                fontWeight: "bolder",
                height: "45px",
              }}
            />
          </Space>
          <Box
            sx={{ width: ["100%", "auto"] }}
            className="rooms"
            onClick={handleRoomDIv}
          >
            <p>
              {rooms} Room, {guests} Guest
            </p>
            {roomshow ? (
              <div className="roomsDiv" ref={roomRef}>
                <div>
                  <p>Rooms</p>
                  <div className="room-counter">
                    <button
                      onClick={() => {
                        dispatch(setRooms(rooms - 1));
                        dispatch(setGuests(1));
                      }}
                      disabled={rooms === 1}
                    >
                      -
                    </button>
                    <p>{rooms}</p>
                    <button
                      onClick={() => {
                        dispatch(setRooms(rooms + 1));
                      }}
                    >
                      +
                    </button>
                  </div>
                </div>
                <div>
                  <p>Guest</p>
                  <div className="room-counter">
                    <button
                      onClick={() => {
                        dispatch(setGuests(guests - 1));
                      }}
                      disabled={guests === 1}
                    >
                      -
                    </button>
                    <p>{guests}</p>
                    <button
                      onClick={() => {
                        dispatch(setGuests(guests + 1));
                      }}
                      disabled={Boolean(rooms * 3 === guests)}
                    >
                      +
                    </button>
                  </div>
                </div>
              </div>
            ) : null}
          </Box>

          <button onClick={handleLinkClick}>Search</button>
        </div>
        <div></div>
      </div>
    </>
  );
}

export default SearchField;
