import axios from 'axios';
export default function authHeader() {
    return {
        'X-API-KEY': 'staykerde@43214',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': '*',
        'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, DELETE'
    };
}

