import React, { useState, useEffect } from 'react';
import { Slider } from '@mui/material';
import {
    Box,
    Typography,
    TextField,
    TextareaAutosize,
    Button,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { filterProductsPrice, filterProductsRating, setLocationFilter, setSelectedAmenitiesUpdate } from '../../../store/slice/filterSlice';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
const FilterProduct = () => {
    const dispatch = useDispatch();
    const { filterProduct, product } = useSelector((state) => state.filterSlice);
    const amenities = ['Pool', 'Gym', 'Spa', 'Restaurant', "Seating area", "TV", "Room Heater", "Mini Fridge"]; // Array of amenities

    const [priceRange, setPriceRange] = useState([0, 0]);
    const [RatingRange, setRatingRange] = useState([1, 3]);
    const [selectedAmenities, setSelectedAmenities] = useState([]);
    const handlePriceRangeChange = (event, newValue) => {
        setPriceRange(newValue);
        dispatch(filterProductsPrice(newValue));
    };
    const handleRatingChange = (event, newValue) => {
        setRatingRange(newValue)
        dispatch(filterProductsRating(newValue))
    }
    const handleAmenityChange = (event) => {
        const amenity = event.target.name;
        const updatedAmenities = selectedAmenities.includes(amenity)
            ? selectedAmenities.filter((selected) => selected !== amenity)
            : [...selectedAmenities, amenity];

        setSelectedAmenities(updatedAmenities);
        // console.log(updatedAmenities)
        dispatch(setSelectedAmenitiesUpdate(updatedAmenities));
    };

    useEffect(() => {
        if (product.length > 0) {
            const minPrice = Math.min(...product.map((product) => product?.hotelBasePrice[0]?.base_price));
            const maxPrice = Math.max(...product.map((product) => product?.hotelBasePrice[0]?.base_price));
            setPriceRange([minPrice, maxPrice]);
        }
    }, [product]);

    return (
        <Box className="p-5">
            <h5 className="text-left">Filter</h5>
            <Box className="search-box">
                <TextField
                    label="Search Hotel"
                    type="Search"
                    name="search"
                    size="small"
                    onChange={(e) => dispatch(setLocationFilter(e.target.value))}
                />
            </Box>
            <Box>
                <div>
                    <Typography id="price-range-slider" gutterBottom>
                        Price Range
                    </Typography>
                    <Slider
                        value={priceRange}
                        onChange={handlePriceRangeChange}
                        valueLabelDisplay="auto"
                        aria-labelledby="price-range-slider"
                        min={Math.min(...product.map((product) => product?.hotelBasePrice[0]?.base_price))}
                        max={Math.max(...product.map((product) => product?.hotelBasePrice[0]?.base_price))}
                    />
                </div>
            </Box>
            {/* <Box>
                <div>
                    <Typography id="price-range-slider" gutterBottom>
                        Rating
                    </Typography>
                    <Slider
                        value={RatingRange}
                        onChange={handleRatingChange}
                        valueLabelDisplay="auto"
                        aria-labelledby="price-range-slider"
                        min={1}
                        max={5}
                        step={0.5}
                    />
                </div>
            </Box>
            <FormGroup>
                <Typography id="price-range-slider" gutterBottom>
                    Hotel Facilities
                </Typography>
                {amenities.map((amenity) => (
                    <FormControlLabel
                        key={amenity}
                        control={
                            <Checkbox
                                checked={selectedAmenities.includes(amenity)}
                                onChange={handleAmenityChange}
                                name={amenity}
                            />
                        }
                        label={amenity}
                    />
                ))}
            </FormGroup> */}
        </Box>
    );
};

export default FilterProduct;
