import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import { Link } from "react-router-dom";
import React from 'react'
import { FaUser } from 'react-icons/fa';
import { useDispatch, useSelector } from "react-redux";
import { logout, } from "../../store/slice/authSlice";
import { AiOutlineLogout } from "react-icons/ai";

const SideBar = () => {
    const dispatch = useDispatch();
    const { isLoggedIn, user } = useSelector(
        (state) => state.authSlice
    );
    return (
        <div>  <div className="customer-sidebar card">
            <div className="card-header  customer-sidebar-header text-light d-flex justify-content-between align-items-center customer-sidebar-header py-3">
                <div className="w-100 text-center">
                    <div className="d-flex justify-content-center">
                        <Stack direction="row" spacing={2}>
                            <Avatar


                                sx={{ width: 56, height: 56 }}
                            >    {user?.name ? user.name.charAt(0) : ""}
                            </Avatar>
                        </Stack>
                    </div>

                    <div className="mt-2">
                        <h6 className="fs-base mb-0 text-light">
                            {user?.name}
                        </h6>
                        <div className="text-accent fs-sm mb-0 text-light">
                            {user?.email}
                        </div>
                    </div>
                </div>

                <a
                    className="d-lg-none d-xl-none mt-2 collapsed"
                    href="#customer-account-menu"
                    data-bs-toggle="collapse"
                    aria-expanded="false"
                >
                    <i className="fas fa-bars fs-24 text-light"></i>
                </a>
            </div>
            <div
                className="card-body d-lg-block collapse "
                id="customer-account-menu"
            >
                <ul className="navbar-nav list-unstyled mb-0 text-center">


                    <li>
                        <button className="btn btn-primary" onClick={() => dispatch(logout())}><AiOutlineLogout /> Logout </button>
                    </li>
                </ul>
            </div>
        </div></div>
    )
}

export default SideBar