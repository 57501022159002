import React from 'react'
import BreadCrumb from '../BreadCrumb'
import { FooterTwo } from '../FooterTwo'
import Dashboard from './Dashboard'
import SideBar from './SideBar'

const Profile = () => {
    return (
        <div>

            <BreadCrumb title={"Profile"} />

            <div className='container'>
                <div className='row p-5'>
                    <div className='col-md-4'>
                        <SideBar />
                    </div>
                    <div className='col-md-8'>

                        <Dashboard />
                    </div>
                </div>
            </div>

            <FooterTwo />

        </div>
    )
}

export default Profile