import myHttp from "./http-sevice-config"
import authHeader from "./auth-header";

export const getAllCity = () => {
    return myHttp.get("api/statelist/101", { headers: authHeader() });
};
export const getAllProduct = () => {
    return myHttp.get("api/listofprop", { headers: authHeader() });
};
export const getProductById = (id) => {
    return myHttp.get(`api/gethotelbyid/${id}`, { headers: authHeader() });
};

export const getUserBooking = (data) => {
    const formData = new FormData();

    for (const key in data) {
        if (data.hasOwnProperty(key)) {
            const value = data[key];

            formData.append(key, value);
        }
    }

    return myHttp.post(`api/bookHotel`, formData, {
        headers: {
            ...authHeader(),
            "Content-Type": "multipart/form-data",
        },
    });
};

export const getUserBookingOnline = (data) => {
    const formData = new FormData();

    for (const key in data) {
        if (data.hasOwnProperty(key)) {
            const value = data[key];

            formData.append(key, value);
        }
    }

    return myHttp.post(`api/checkout`, formData, {
        headers: {
            ...authHeader(),
            "Content-Type": "multipart/form-data",
        },
    });
};

export const getPrice = (data) => {
    const formData = new FormData();

    for (const key in data) {
        if (data.hasOwnProperty(key)) {
            const value = data[key];

            formData.append(key, value);
        }
    }

    return myHttp.post(`api/getPrice`, formData, {
        headers: {
            ...authHeader(),
            "Content-Type": "multipart/form-data",
        },
    });
};

export const userLogin = (data) => {
    const formData = new FormData();

    for (const key in data) {
        if (data.hasOwnProperty(key)) {
            const value = data[key];

            formData.append(key, value);
        }
    }

    return myHttp.post(`api/guestlogin`, formData, {
        headers: {
            ...authHeader(),
            "Content-Type": "multipart/form-data",
        },
    });
};

export const userSignUp = (data) => {
    const formData = new FormData();

    for (const key in data) {
        if (data.hasOwnProperty(key)) {
            const value = data[key];

            formData.append(key, value);
        }
    }

    return myHttp.post(`api/gutestregister`, formData, {
        headers: {
            ...authHeader(),
            "Content-Type": "multipart/form-data",
        },
    });
};
export const uploadHotelDeatilsFile = (data) => {
    const formData = new FormData();

    for (const key in data) {
        if (data.hasOwnProperty(key)) {
            const value = data[key];

            formData.append(key, value);
        }
    }
    console.log(JSON.stringify(formData, null, 2))
    return myHttp.post(`api/userregister`, formData, {
        headers: {
            ...authHeader(),
            "Content-Type": "multipart/form-data",
        },
    });
};



export const getAreaList = () => {
    return myHttp.get("api/arealist", { headers: authHeader() });
};

export const getHotelListById = (id) => {
    return myHttp.get(`api/gethotelbysector/${id}`, { headers: authHeader() });
};