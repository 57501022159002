import "./Home.css";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Banner from "./Banner";
import Footer from "./Footer";
import { FooterTwo } from "../FooterTwo";
import Locations from "./Locations";
import StickyNavbar from "./StickyNavbar";
import NavbarHome from "./NavbarHome";
import SearchField from "./SearchField";
import WorldwideMarketing from "./WorldwideMarketing";
import BestHotal from "./BestHotal";
import TrandingHotal from "./TrandingHotal";
import TopLocation from "./TopLocation";
import HomeSliderEffect from "./HomeSliderEffect";
import TopBar from "./TopBar";
import { initializeProducts } from "../../store/slice/productSlice";
import { initializeFilterProducts } from "../../store/slice/filterSlice";

function HomeMain() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(initializeProducts());
    dispatch(initializeFilterProducts());
  }, [dispatch]);
  return (
    <div className="App">
      {/* <StickyNavbar /> */}
      {/* <TopBar /> */}
      <NavbarHome />
      <HomeSliderEffect />
      <SearchField />
      <BestHotal />
      <TopLocation />
      <h4 className="text-right col-md-10 m-auto   text-danger mt-3">
        Trending Hotels{" "}
      </h4>
      <TrandingHotal />
      <Banner />
      {/* <Footer /> */}
      <FooterTwo />
      {/* 
      
      <WorldwideMarketing />
       */}
    </div>
  );
}

export default HomeMain;
