import { combineReducers } from "@reduxjs/toolkit";
import searchslice from "./searchslice";
import filterSliceData from "./filterSlice";
import productSlice from "./productSlice";
import authenticationSlice from "./authSlice";
// Import other reducers

const rootReducer = combineReducers({
    searchslice: searchslice,
    filterSlice: filterSliceData,
    productSlice: productSlice,
    authSlice: authenticationSlice
    // Add other reducers here
});

export default rootReducer;
