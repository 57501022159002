import React, { useEffect } from 'react';
import { AutoComplete } from 'antd';
import { useSelector, useDispatch } from "react-redux";
import { fetchCityList } from '../../store/slice/searchslice';
import { ReactSearchAutocomplete } from "react-search-autocomplete";
const AutoCompleteSearch = ({ onChangevalue }) => {

    const { cityList } = useSelector(
        (state) => state.searchslice
    );
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchCityList());
    }, []);
    const handleSearch = async (value) => {
        // Perform the search or filtering based on the entered value

    };

    return (
        <AutoComplete
            style={{ width: "100%", borderRadius: 8, height: "40px" }}
            placeholder="Select a Area"
            dataSource={cityList.map((city) => city?.area)}
            size="large"
            className="stakr-search-large"
            onSelect={onChangevalue}
            onSearch={onChangevalue}
            optionLabelProp="label"
        />

    );
};

export default AutoCompleteSearch;
