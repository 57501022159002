import React, { useState, useRef, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import logo from "../../assets/image/Union.png";
import "react-select-search/style.css";
import moment from "moment";
import "moment/locale/en-au";
import { Box, Grid } from "@mui/material";
import { hotels } from "../db";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import { FaCrosshairs } from "react-icons/fa";
import Logo from "../../assets/image/Union.png";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Container } from "react-bootstrap";
function NavbarSticky() {
  const history = useHistory();
  const [roomCounter, setroomCounter] = useState(1);
  const [rooms, setrooms] = useState(false);
  const [roomsNum, setroomsNum] = useState(1);

  const handleRoomDIv = () => {
    setrooms(true);
  };
  // console.log(startDate);
  let roomRef = useRef();

  const handleLinkClick = () => {
    history.push("/hotels");
  };

  useEffect(() => {
    let handler = (event) => {
      if (roomRef.current && !roomRef.current.contains(event.target)) {
        setrooms(false);
      }
    };

    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  const handleSearch = (value) => {
    // Perform search logic here
    console.log("Search value:", value);
  };
  const [selectedCity, setSelectedCity] = useState("Delhi");

  const handleOnSearch = (string, results) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    console.log(string, results);
  };

  const handleOnHover = (result) => {
    // the item hovered
    console.log(result);
  };

  const handleOnSelect = (item) => {
    // the item selected

    setSelectedCity(item.city);
    console.log(item);
  };

  const handleOnFocus = () => {
    console.log("Focused");
  };

  const formatResult = (item) => {
    return (
      <>
        <span style={{ display: "block", textAlign: "left" }}>
          {" "}
          {item.city}
        </span>
      </>
    );
  };

  const handleNearMe = () => {
    // Handle "Near Me" logic here
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          // Perform location-based logic here
          console.log("Latitude:", position.coords.latitude);
          console.log("Longitude:", position.coords.longitude);
          setSelectedCity("Delhi");
        },
        (error) => {
          console.error("Error getting location:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported");
    }
  };

  const [selectedStartDate, setSelectedStartDate] = React.useState(
    moment().format("YYYY-MM-DD")
  );
  const [selectedEndDate, setSelectedEndDate] = React.useState(
    moment().add(1, "day").toDate()
  );

  const handleDateChange = (start, end) => {
    setSelectedStartDate(start);
    setSelectedEndDate(end);
    // Perform any additional logic with the selected dates
    console.log("Selected Start Date:", start);
    console.log("Selected End Date:", end);
  };

  return (
    <>
      <Container>
        <header>
          <Navbar expand="lg">
            <Navbar.Brand href="/">
              {" "}
              <img src={Logo} alt="Union" class="img-fluid" width={120} />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav justify-content-end;">
              <Nav className="ms-auto backgundnav-m ">
                <Nav.Link href="#link" className=" fw-bolder">
                  Hotels
                </Nav.Link>
                <Nav.Link href="#home" className=" fw-bolder ">
                  Services
                </Nav.Link>
                <Nav.Link href="#link" className=" fw-bolder ">
                  Blog
                </Nav.Link>
                <Nav.Link href="#home" className=" fw-bolder ">
                  Gallery
                </Nav.Link>
                <Nav.Link href="#link" className=" fw-bolder ">
                  Events
                </Nav.Link>
                <button
                  style={{ width: "100px" }}
                  className="btn   btn-danger btn-sm pr-3"
                >
                  Login
                </button>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </header>
      </Container>
    </>
  );
}

export default NavbarSticky;
