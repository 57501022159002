import styled from 'styled-components';

export const PromoCard = styled.div`
  /* border-left: 1px solid #eee; */
  padding: 0 0px;
  cursor: pointer;
`;

export const PromoCardCard = styled.div`
  border-radius: 4px;
  padding: 8px 8px 7px;
  display: flex;
  align-items: center;
  @media (max-width: 768px) {
    padding: 0px 0px 0px;
  }
`;

export const PromoCardCTA = styled.div`
  cursor: pointer;
`;

export const PromoCardWhite = styled.div`
  color: #fff;
`;

export const PromoCardIconWrapper = styled.div`
  display: inline-block;
  width: 40px;
  height: 35px;
  border-radius: 2px;
  overflow: hidden;
  color: white;
  font-size: 0;
  line-height: 0;
  box-sizing: border-box;

  svg {
    margin: 0 auto;
    display: block;
    width: 20px;
    height: 100%;
    cursor: pointer;
  }
  @media (max-width: 768px) {
    color: #000;
    
    svg {
    margin: 0 auto;
    display: block;
    font-size: 10px;
    cursor: pointer;
  }
  }
`;

export const PromoCardContentWrapper = styled.div`
  display: inline-block;
  width: calc(100% - 50px);
  margin-left: 7px;
  white-space: nowrap;
`;

export const PromoCardText = styled.div`
  color: #fff;
  margin-bottom: 2px;
  @media (max-width: 768px) {
    color: #000000;
    font-size: 13px;
  }

`;

export const PromoCardNoBorder = styled.div`
  border: none;
`;

export const SVGColor = styled.div`
  svg > path {
    stroke: #000;
  }
`;

export const SVGColorWhite = styled.div`
  svg > path {
    stroke: #fff;
  }
`;

export const SerchBarHeader = styled.div`
width: "30%";
 @media (max-width: 768px) {
  width: "100%";
  }
`;


export const TopLocationBg = styled.div`
  position: relative;
  padding-bottom: 1%;
    padding-top: 1%;
  
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(https://hotelwedlock.com/public/uploads/media/zHcKAjp6EMyyO22UXOaMx2MfT9IVTH4HW00kLpS5.jpg?w=248&fit=crop&auto=format);
    background-size: cover;
    background-repeat: no-repeat;
    opacity: 0.2;
    z-index: -1;
    background-position-y: top;
    /* background-position-x: -300px; */
  }
  @media (max-width: 768px) {
    max-height: 100% ;
  }
  

  h2 {
    font-family: 'Open Sans', sans-serif;
    color: #293448;
    font-weight: 900  !important;
    margin: 0;
    line-height: 1.3;
    font-size: 1.625rem;
    word-break: break-word;
  }
`;

export const BestHotelBg = styled.div`
  position: relative;
  padding-bottom: 1%;
    padding-top: 1%;
  
  @media (max-width: 768px) {
    max-height: 100% ;
  }
  

  h4 {
    /* font-family: 'Open Sans', sans-serif; */
    color: #293448;
    font-weight: 600;
    margin: 0;
    line-height: 1.3;
    font-size: 1.425rem;
    word-break: break-word;
  }
`;
export const LocationsWrapper = styled.div`
  max-width: 60%;
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;
  
  &:hover {
    overflow-x: scroll;
  }
  
  &::-webkit-scrollbar {
    width: 8px;
    background-color: #f5f5f5;
  }
  
  &::-webkit-scrollbar-thumb {
    background-color: #000000;
    border-radius: 4px;
  }
  
  &::-webkit-scrollbar-thumb:hover {
    background-color: #555555;
  }
`;


