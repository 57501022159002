import React, { useState } from 'react'
import { hotels } from '../db'
import HotelCard from '../ColumnView/Index'
import { BiLeftArrowAlt, BiRightArrowAlt } from "react-icons/bi";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Typography, Link } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { BestHotelBg } from './Styles';
import { useSelector, } from "react-redux";
import CircularProgress from '@mui/material/CircularProgress';

const BestHotal = (props) => {
    const { products, isLoading } = useSelector(
        (state) => state.productSlice
    );
    const [slider, setSlider] = useState(null);
    const settings = {
        dots: false,
        arrows: false,
        fade: false,
        infinite: true,
        autoplay: false,
        speed: 2000,
        autoplaySpeed: 2000,
        pauseOnHover: true,
        slidesToShow: 3,
        slidesToScroll: 3,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <BestHotelBg className='container-fluid small ' >
            {isLoading ? (<>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                    <CircularProgress />
                </div>
            </>) : (<>
                <Typography variant="h4" className='text-right col-md-10 m-auto   text-danger mt-1'>{props?.title ?? "Best Hotel For You"}  <span style={{ float: "right", fontSize: 20 }}><Link href="/hotels" >
                    All Hotels   </Link></span></Typography>

                <div className="position-relative col-md-10 m-auto p-2 col-xs-12">
                    <button
                        type="button"
                        className="btn btn-light buttonslider-left btn-badge position-absolute"
                        style={{
                            left: "0%",
                            top: "50%",
                            transform: "translate(-100%, -100%)",
                            zIndex: 2,
                        }}
                        onClick={() => slider?.slickPrev()}
                    >
                        <BiLeftArrowAlt />
                    </button>
                    <button
                        type="button"
                        className="btn btn-light buttonslider-right btn-badge position-absolute"
                        style={{
                            right: "0%",
                            top: "50%",
                            transform: "translate(100%, -100%)",
                            zIndex: 2,
                        }}
                        onClick={() => slider?.slickNext()}
                    >
                        <BiRightArrowAlt />
                    </button> <Slider {...settings} className='h-100 w-100' ref={(slider) => setSlider(slider)}>
                        {products.map((item, index) => {
                            return (
                                <HotelCard key={uuidv4()} hotel={item} />
                            );
                        })}
                    </Slider>  </div>
            </>)

            }

        </BestHotelBg>
    )
}

export default BestHotal