import { useState, useEffect } from "react";
import { ImageCarousel } from "./ImageCarousel";
import styled from "styled-components";
import Stack from "@mui/material/Stack";
import { Button, Card, Row, Col } from "react-bootstrap";
import { Box, Grid, IconButton } from "@mui/material";
import { useHistory, Link } from "react-router-dom";

import { FaPhoneAlt, FaWhatsapp } from "react-icons/fa";
import { MdLocationPin } from "react-icons/md";
import Star from "../Star";
import { useSelector } from "react-redux";
import DescriptionsRef from "../RoomDetail/DescriptionsRef";
import { Typography } from "antd";
const Div = styled.div`
  display: flex;
  /* 
  padding: 4% 0; */
  margin: 2%;
  border-bottom: 1px solid #e6e6e6;
  @media (max-width: 760px) {
    flex-direction: column;
  }
`;

const ItemHead = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
`;

const LDiv = styled.div`
  width: 40%;
  @media (max-width: 760px) {
    width: 100%;
  }
`;
const OffferBadge = styled.div`
  position: absolute;
  top: -2px;
  right: -2px;
  width: 100px;
  height: 100px;
  background-color: #f05945;
  color: #fff;
  clip-path: polygon(0 0, 100% 0, 100% 100%);
  text-align: right;
  padding-right: 15px;
  padding-top: 10px;
  z-index: 1;
`;

const RDiv = styled.div`
  width: 90%;
  padding: 1%;
  & div {
    flex: 2;
  }
`;

const RateDiv = styled.div`
  margin-left: 52px;
`;

const Alert = styled.p`
  color: #ee2e24;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.025em;
  text-align: left;
  flex: 0.5;
`;

const Head = styled.p`
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: -0.005em;
  text-align: left;
  color: #424242;
`;

const Location = styled.p`
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  /* line-height: 5px; */
  letter-spacing: -0.005em;
  text-align: left;
  color: #424242;
`;

const Rating = styled.div`
  position: absolute;
  width: 46px;
  height: 20px;
  background: #5ecc37;
  border-radius: 2px;
  border-radius: 5%;
  color: #ffffff;
  padding: 0 0.3%;
  margin: 2px 0;
`;

const Block = styled.div`
  display: flex;
  flex-direction: column;
`;

const BottomDiv = styled.div`
  display: flex;
  margin-top: 3%;
  justify-content: space-between;
  width: 90%;

  @media (max-width: 760px) {
    flex-direction: column;
    justify-self: start;
    align-items: flex-start;
    font-size: 1rem;
    width: 100%;
  }
`;

const Span1 = styled.span`
  /* font-size: 26px;
  font-style: normal;
  font-weight: 600;
  line-height: 31px;
  letter-spacing: -0.005em;
  text-align: left; */
  /* position: static; */
  /* width: 65px;
  height: 31px; */
  left: 0px;
  top: 0px;

  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 31px;
  letter-spacing: -0.005em;

  /* Logo */

  color: #ee2e24;
  margin: 0px 4px;
`;

const Span2 = styled.span`
  /* font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: -0.005em;
  text-align: left; */
  position: static;
  width: 47px;
  height: 19px;
  left: 74px;
  top: 6px;

  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.005em;
  text-decoration-line: line-through;

  color: #636363;
  margin: 0px 4px;
`;

const Span3 = styled.span`
  /* font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: -0.005em;
  text-align: left; */
  position: static;
  width: 53px;
  height: 17px;
  left: 130px;
  top: 7px;

  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  margin: 0px 4px;
  /* identical to box height */

  letter-spacing: -0.005em;

  /* Accent Yellow Dark */

  color: #d79a12;
`;

const TagDiv = styled.div`
  margin-bottom: 3%;
`;

const P1 = styled.p`
  width: 106px;
  height: 15px;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 8px;
  /* identical to box height */

  letter-spacing: -0.005em;

  color: #636363;
`;

export const HotelInfo = ({ key, data }) => {
  const {
    hotelGallery,
    name,
    rating,
    price,
    beds,
    City,
    hotelName,
    hotelBasePrice,
    hotel_id,
  } = data;
  const { startDate, endDate, rooms, guests } = useSelector(
    (state) => state.searchslice
  );
  const [finalPrice, setFinalPrice] = useState(
    Number(hotelBasePrice[0]?.base_price)
  );

  const history = useHistory();

  const handleViewDetails = () => {
    history.push(`/hoteldetail/${hotel_id}`);
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optionally, use 'auto' or 'smooth' for smooth scrolling
    });
  };

  const handleBookNow = () => {
    history.push(`/payment/${hotel_id}`);
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optionally, use 'auto' or 'smooth' for smooth scrolling
    });
  };

  const basePriceUser =
    finalPrice + (Math.floor(Math.random() * (1000 - 500 + 1)) + 500);
  const salePrice = finalPrice;

  const discountPercentage =
    ((basePriceUser - salePrice) / basePriceUser) * 100;

  return (
    <>
      <Card className="p-1 m-1 position-relative">
        <OffferBadge>
          <b>{parseInt(discountPercentage)}%</b> <br />
          <span>off</span>
        </OffferBadge>
        <Card.Body className="p-2">
          <Grid container spacing={2} className="p-3 ">
            <Grid item sm={5} lg={5} md={5} xs={12}>
              <ImageCarousel
                onClick={handleViewDetails}
                key={key}
                data={hotelBasePrice[0]?.images}
              />
            </Grid>
            <Grid item sm={4} lg={4} md={4} xs={12}>
              <Block>
                <ItemHead>
                  <Head onClick={handleViewDetails}>{hotelName}</Head>
                  <Location>{data?.City}</Location>
                </ItemHead>
                <div>
                  <span>
                    <Star stars={data.rating || 4} />
                    (300 Ratings) . Good
                  </span>
                </div>
                <div>
                  <DescriptionsRef
                    htmlContent={data?.hotelBasePrice[0]?.description.substring(
                      0,
                      50
                    )}
                  />
                </div>
                <div style={{ marginTop: "10px" }}>
                  <Card.Img
                    src="Images/Facilities.png"
                    alt=""
                    style={{ width: "85%", height: "auto" }}
                  />
                </div>
              </Block>
            </Grid>
            <Grid
              item
              sm={3}
              lg={3}
              md={3}
              xs={12}
              style={{
                borderLeft: "1px solid #ddd",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div className="w-100 text-right text-lg-center">
                <Span1>₹{finalPrice}</Span1>
                <Span2>₹{basePriceUser}</Span2>
              </div>
              <Stack
                position={["relative", "relative"]}
                sx={{ bottom: 2 }}
                spacing={2}
                direction="row"
              >
                <Box sx={{ justifySelf: ["start", "end"] }}>
                  <IconButton
                    onClick={() => {
                      const address = data?.City;
                      const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                        address
                      )}`;
                      window.open(googleMapsUrl);
                    }}
                    size="sm"
                    variant="outlined"
                    color="error"
                  >
                    <MdLocationPin />
                  </IconButton>
                  <a
                    target="_blank"
                    href={`https://wa.me/+91 95-990-85-990?text=${hotelName}`}
                  >
                    <IconButton size="sm" variant="outlined" color="success">
                      <FaWhatsapp />
                    </IconButton>
                  </a>
                  <IconButton
                    onClick={() => window.open(`tel:+91 95-990-85-990`)}
                    size="sm"
                    variant="outlined"
                    color="primary"
                  >
                    <FaPhoneAlt />
                  </IconButton>
                  {/* <Link to={`/hoteldetail/${data.id}`}> */}
                  {/* </Link> */}
                  &nbsp;
                  {/* <Link to={`/payment/${data.id}`}> */}
                  {/* </Link> */}
                </Box>
              </Stack>
              <Button
                onClick={handleBookNow}
                className="btn w-100 mb-1 btn-dadge btn-success p-1"
                size="sm"
              >
                Book Now
              </Button>
              <Button
                onClick={handleViewDetails}
                className="btn w-100 btn-sm btn-success p-1"
              >
                View More
              </Button>
            </Grid>
          </Grid>
        </Card.Body>
      </Card>
    </>
  );
};
