import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import moment from "moment";
import "moment/locale/en-au";
import { getAreaList } from "../../baseApi/Api";
// Define an async thunk for fetching the city list
export const fetchCityList = createAsyncThunk(
    'searchslice/fetchCityList',
    async () => {
        try {
            const response = await getAreaList();
            const tempData = response.data;
            console.log('data: filter', response);
            return tempData;
        } catch (error) {

            throw new Error('Failed to fetch city list');
        }
    }
);

const initialState = {
    city: "",
    cityList: [],
    startDate: null,
    endDate: null,
    rooms: 1,
    guests: 1,
    isLoadingCityList: false,
    errorCityList: null,
};

const searchslice = createSlice({
    name: "searchslice",
    initialState,
    reducers: {
        setCity: (state, action) => {
            state.city = action.payload;
        },
        setStartDate: (state, action) => {
            state.startDate = action.payload;
        },
        setEndDate: (state, action) => {
            state.endDate = action.payload;
        },
        setRooms: (state, action) => {
            state.rooms = action.payload;
        },
        setGuests: (state, action) => {
            state.guests = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCityList.pending, (state) => {
                state.isLoadingCityList = true;
                state.errorCityList = null;
            })
            .addCase(fetchCityList.fulfilled, (state, action) => {
                state.isLoadingCityList = false;
                state.errorCityList = null;
                state.cityList = action.payload;
            })
            .addCase(fetchCityList.rejected, (state, action) => {
                state.isLoadingCityList = false;
                state.errorCityList = action.error.message;
            });
    },
});

export const {
    setCity,
    setStartDate,
    setEndDate,
    setRooms,
    setGuests,
} = searchslice.actions;

export default searchslice.reducer;
