import axios from "axios";

const axiosInstance = axios.create({
    baseURL: "https://staykr.in/",
    headers: {
        "Content-type": "application/json",
    }
});

axiosInstance.interceptors.response.use((response) => {
    return response;
}, (error) => {
    if (error.response && error.response.status === 401) {
        // Redirect to login page and reload the application
        localStorage.clear()
        window.location.href = "/";
        window.location.reload();
    }
    return Promise.reject(error);
});

export default axiosInstance;
