function Banner() {
  return (
    <>
      <div className="banner">
        <img src="/Images/BANNER.jpg" alt="Rectangle 426" />
      </div>
    </>
  );
}

export default Banner;
