import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { userLogin, userSignUp } from "../../baseApi/Api";

const user = JSON.parse(localStorage.getItem("user"));
const initialState = user
    ? { isLoggedIn: true, isRegistered: false, isLoading: false, error: "", user }
    : { isLoggedIn: false, isRegistered: false, isLoading: false, error: "", user: null };

export const loginUserAsync = createAsyncThunk(
    "authentication/loginUser",
    async (loginData, thunkAPI) => {
        try {
            const response = await userLogin(loginData);
            //
            if (response.data.status === 200 && response.data.data) {
                let resData = response.data.data
                let data = {
                    name: resData?.firstname,
                    email: resData?.email,
                    mobile: resData?.mobile,
                    password: resData?.password,
                    id: resData?.id
                }
                localStorage.setItem("user", JSON.stringify(data));
                return data;

            } else {
                return thunkAPI.rejectWithValue({ error: "Invalid credentials, Try again !!" });
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: "Invalid credentials, Try again !!" });
        }
    }
);



export const signupUserAsync = createAsyncThunk(
    "authentication/signupUser",
    async (data, thunkAPI) => {
        try {
            const response = await userSignUp(data);

            if (response.status === 200 && !response.data.message.includes("exist")) {

                let resData = response.data.data;
                console.log("resData", resData)
                let data = {
                    name: resData?.firstname,
                    email: resData?.email,
                    mobile: resData?.mobile,
                    password: resData?.password,
                    id: resData?.id
                }
                localStorage.setItem("user", JSON.stringify(data));
                return data;
            } else {
                return thunkAPI.rejectWithValue({ error: response.data.message });
            }

        } catch (error) {
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
);

export const authenticationSlice = createSlice({
    name: "authentication",
    initialState,
    reducers: {
        clearState: (state) => {
            state.isLoading = false;
            state.error = "";
        },
        clearRegistered: (state) => {
            state.isRegistered = false;
            state.error = "";
        },
        updateCurrentUserProfile: (state, action) => {
            state.user.userProfile = action.payload;
        },
        logout: (state) => {
            localStorage.clear();
            state.isLoggedIn = false;
            state.user = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(loginUserAsync.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(loginUserAsync.fulfilled, (state, action) => {
                console.log("payload", action)
                state.isLoading = false;
                state.isLoggedIn = true;
                state.error = "";
                state.user = action.payload;
                state.tempUserInfo = action.payload;
            })
            .addCase(loginUserAsync.rejected, (state, { payload }) => {
                state.isLoading = false;
                state.error = payload.error;
                state.isLoggedIn = false;
                state.user = null;
            })
            .addCase(signupUserAsync.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(signupUserAsync.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isLoggedIn = true;
                state.isRegistered = true;
                state.error = "";
                state.user = action.payload;
                state.tempUserInfo = action.payload;
            })
            .addCase(signupUserAsync.rejected, (state, { payload }) => {
                //console.log("Error:", payload.error);
                state.isLoading = false;
                state.error = payload.error;
                state.isRegistered = false;
            });
    },
});

export const { clearState, clearRegistered, logout, updateCurrentUserProfile } = authenticationSlice.actions;
export const authSelector = (state) => state.authentication;



export default authenticationSlice.reducer;


