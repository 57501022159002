import React, { useRef } from "react";
import { TextField, Grid, FormHelperText, MenuItem, FormControl, InputLabel, Select } from "@mui/material";

const PersonalInfo = (props) => {
    const { formik } = props;
    const fileInputRef = useRef(null);

    const handleButtonClick = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        formik.setFieldValue("file", file);
    };


    return (
        <Grid container spacing={2}>
            <Grid item xs={6}>
                <TextField
                    name="firstName"
                    label="First Name"
                    variant="outlined"
                    size="small"
                    fullWidth
                    required
                    value={formik.values.firstName}
                    onChange={formik.handleChange}
                    error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                    helperText={formik.touched.firstName && formik.errors.firstName}
                />

            </Grid>
            <Grid item xs={6}>
                <TextField
                    name="lastName"
                    label="Last Name "
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={formik.values.lastName}
                    onChange={formik.handleChange}
                    error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                    helperText={formik.touched.lastName && formik.errors.lastName}
                />

            </Grid>
            <Grid item xs={12}>
                <TextField
                    name="email"
                    label="Email "
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                />

            </Grid>
            <Grid item xs={12}>
                <TextField
                    name="phone"
                    label="Phone Number"
                    variant="outlined"
                    type="tel"
                    fullWidth
                    size="small"
                    value={formik.values.phone}
                    onChange={formik.handleChange}
                    error={formik.touched.phone && Boolean(formik.errors.phone)}
                    helperText={formik.touched.phone && formik.errors.phone}
                />

            </Grid>
            <Grid item xs={12}>
                <FormControl variant="outlined" size="small" fullWidth>
                    <InputLabel htmlFor="document">Select Document</InputLabel>
                    <Select
                        name="document"
                        value={formik.values.document}
                        onChange={formik.handleChange}
                        label="Select Document"
                        error={formik.touched.document && Boolean(formik.errors.document)}
                    >
                        <MenuItem value="Aadhar">Aadhar</MenuItem>
                        <MenuItem value="Pan">Pan</MenuItem>
                    </Select>
                    {formik.touched.document && formik.errors.document && (
                        <FormHelperText>{formik.errors.document}</FormHelperText>
                    )}
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    name="documentNumber"
                    label="Id Number"
                    variant="outlined"
                    type="text"
                    fullWidth
                    size="small"
                    value={formik.values.documentNumber}
                    onChange={formik.handleChange}
                    error={formik.touched.documentNumber && Boolean(formik.errors.documentNumber)}
                    helperText={formik.touched.documentNumber && formik.errors.documentNumber}
                />
            </Grid>

            <Grid item xs={12}>
                <TextField
                    type="file"
                    name="aadhar"
                    accept="image/*"
                    onChange={formik.handleChange}
                    // sx={{ display: "none" }}
                    label=""
                    variant="outlined"
                    size="small"
                    fullWidth
                    error={formik.touched.aadhar && Boolean(formik.errors.aadhar)}
                    value={formik.values.aadhar}
                />
                <label>Upload file</label>
            </Grid>

        </Grid>
    );
};

export default PersonalInfo;
