import { Offer1 } from "./offers/Offer1";
import styled from "styled-components";
import { Filters } from "./Filters/Filters";
import { HotelInfo } from "./HotelInfo/HotelInfo";
import { TopFilterBar } from "./Filters/TopFilterBar";
import { FooterTwo } from "./FooterTwo";
import { COVID } from "./COVID";
import { Navbar2 } from "../Components/Navbar2/Navbar2";
import { hotels } from "./db";
import { v4 as uuidv4 } from "uuid";
import { useState, useEffect } from "react";
import NavbarSticky from "./Home/NavbarSticky";
import TrandingHotal from "./Home/TrandingHotal";
import Footer from "./Home/Footer";
import { useLocation } from "react-router-dom";
import { Grid } from "@mui/material";
import BreadCrumb from "./BreadCrumb";
import ShortFiltrer from "./Filters/Shorting";
import { useDispatch, useSelector } from "react-redux";
import HotelCard from "./ColumnView/Index";
import FilterProduct from "./Filters/filter";
import {
  fetchProductsByArea,
  initializeFilterProducts,
  seLocationFilterData,
} from "../store/slice/filterSlice";

const MainDiv = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const HotelOptions = (props) => {
  const [cardData, setCardData] = useState([]);
  const disaptch = useDispatch();
  const location = useLocation();
  const { city, cityList, startDate, endDate, rooms, guests } = useSelector(
    (state) => state.searchslice
  );

  const { view, price, filterProduct } = useSelector(
    (state) => state.filterSlice
  );
  const { products } = useSelector((state) => state.productSlice);
  console.log("los", location.state);
  const queryParams = new URLSearchParams(window.location.search);
  const locationData = queryParams.get("location");
  console.log("locationData", locationData);
  useEffect(() => {
    disaptch(fetchProductsByArea(locationData));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationData]);
  return (
    <div style={{ position: "realtive" }}>
      <BreadCrumb title={"Hotels"} />
      <div className="w-100 mt-3 ">
        {/* <COVID /> */}
        <MainDiv className="m-auto">
          <Grid spacing={2} container>
            {/* <Grid
              sx={{
                display: ["none", "block"],
                position: "sticky",
                top: 0,
                alignSelf: "flex-start",
                height: "100%",
                marginTop: "1rem", // Adjust the margin as needed
              }}
              item
              sm={3}
              xs={12}
              md={3}
            >
              <FilterProduct />
            </Grid> */}
            <Grid className="m-auto" item sm={9} xs={12} md={9}>
              <ShortFiltrer />
              {filterProduct.length > 0 ? (
                <>
                  {view === "column" ? (
                    <Grid container spacing={1}>
                      {filterProduct?.map((item, index) => {
                        return (
                          <Grid item sm={4} md={4} xs={12}>
                            <HotelCard key={uuidv4()} hotel={item}></HotelCard>
                          </Grid>
                        );
                      })}
                    </Grid>
                  ) : (
                    <>
                      {filterProduct.map((item, index) => {
                        return <HotelInfo key={uuidv4()} data={item} />;
                      })}
                    </>
                  )}
                </>
              ) : (
                <>Hotel Not Found</>
              )}

              {/* <TrandingHotal city={location?.state} /> */}
            </Grid>
          </Grid>
        </MainDiv>
        <FooterTwo />
      </div>
    </div>
  );
};
