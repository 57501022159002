import React, { useState } from "react";
import { Grid, Typography } from "@mui/material";
import { ImageList, ImageListItem, ImageListItemBar } from "@mui/material";
import BreadCrumb from "../BreadCrumb";
import { FooterTwo } from "../FooterTwo";
import { Link } from "react-router-dom";

const Locations = () => {
  const [indiaLocations, setIndiaLocations] = useState([
    {
      img: "https://cf.bstatic.com/xdata/images/city/600x600/684765.jpg?k=3f7d20034c13ac7686520ac1ccf1621337a1e59860abfd9cbd96f8d66b4fc138&o=",
      title: "New Delhi",
    },
    {
      img: "https://cf.bstatic.com/xdata/images/city/600x600/684534.jpg?k=d1fe86c22f2433f4e2dda14ddcbe80feb024b0fb30305e5684a1241fba5d4cff&o=",
      title: "Bangalore",
    },
    {
      img: "https://cf.bstatic.com/xdata/images/city/600x600/684534.jpg?k=d1fe86c22f2433f4e2dda14ddcbe80feb024b0fb30305e5684a1241fba5d4cff&o=",
      title: "Gurugram",
    },
    {
      img: "https://cf.bstatic.com/xdata/images/city/600x600/684716.jpg?k=4c3f55236cffa6597afa0ef11a9f012636f535bf9cc6c0e2ed8142e01fa14766&o=",
      title: "Manali",
    },
    {
      img: "https://cf.bstatic.com/xdata/images/city/600x600/684716.jpg?k=4c3f55236cffa6597afa0ef11a9f012636f535bf9cc6c0e2ed8142e01fa14766&o=",
      title: "Agra",
    },

    // Add more location objects as needed
  ]);

  return (
    <div>
      <BreadCrumb title="Location" />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          &nbsp;
        </Grid>
        <Grid item xs={12}>
          <ImageList cols={3}>
            {indiaLocations.map((location) => (
              <ImageListItem key={location.img}>
                <Link
                  onClick={() => {
                    window.scrollTo({
                      top: 0,
                      behavior: "smooth", // Optionally, use 'auto' or 'smooth' for smooth scrolling
                    });
                  }}
                  to={{
                    pathname: "/hotels",
                    state: { city: location.title },
                  }}
                >
                  <img
                    src={location.img}
                    // style={{ maxWidth: "300px", maxHeight: "300px" }}
                    alt={location.title}
                    className="w-100 h-100"
                  />
                  <ImageListItemBar title={location.title} />
                </Link>
              </ImageListItem>
            ))}
          </ImageList>
        </Grid>
      </Grid>

      <FooterTwo />
    </div>
  );
};

export default Locations;
