import "./PaymentStyle.css";

export function PaymentCompleteClosed() {
  return (<></>
    // <div className="payCompleteClosed">
    //   <div className="payClosedB1">2</div>
    //   <div className="payClosedB2">Complete your booking.</div>
    // </div>
  );
}
