import React, { useState } from "react";
import { Drawer, IconButton } from '@mui/material';
import { BsFillGridFill, BsList } from "react-icons/bs";

import { Box, Grid } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useDispatch, useSelector } from "react-redux";
import { setPriceShorting, setView } from "../../../store/slice/filterSlice";
import { FaFilter } from "react-icons/fa";
import FilterProduct from "../filter";
const ShortFiltrer = () => {
    const dispatch = useDispatch()
    const [open, setOpen] = useState(false);
    const { view, price, filterProduct } = useSelector(
        (state) => state.filterSlice
    );

    const handleToggleDrawer = () => {
        setOpen(!open);
    };
    return (
        <Box sx={{ height: "5rem" }}>
            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} >
                <Grid item md={4} sx={{ display: ["none", "block"] }}>

                    <IconButton className={`${view === "column" ? "text-primary" : ""}`} onClick={() => dispatch(setView("column"))} >
                        <BsFillGridFill />
                    </IconButton>

                    <IconButton className={`${view === "list" ? "text-primary" : ""}`} onClick={() => dispatch(setView("list"))} >
                        <BsList />
                    </IconButton>
                </Grid>
                <Grid item md={4} sx={{ display: ["none", "block"] }}>
                    <p style={{ fontSize: "1rem" }}>{filterProduct.length} Staykr Hotel India</p>
                </Grid>
                <Grid item md={4} sx={{ display: ["block", "block"] }} >
                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                        <InputLabel id="demo-select-small"> Shorting</InputLabel>
                        <Select
                            labelId="demo-select-small"
                            id="demo-select-small"
                            // value={age}
                            size="small"
                            label=" Price(lowest)"
                            onChange={(e) => dispatch(setPriceShorting(e.target.value))}

                        >
                            <MenuItem value="lowest">
                                Price(lowest)
                            </MenuItem>
                            <MenuItem value="highest">Price(highest)</MenuItem>
                            <MenuItem value="a-z">Price(a-z)</MenuItem>
                            <MenuItem value="z-a">Price(z-a)</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item md={4} sx={{ display: ["block", "none"] }} >
                    <IconButton onClick={() => setOpen(true)} >
                        <FaFilter />
                    </IconButton>
                </Grid>
            </Grid>
            <Drawer
                anchor="left"
                open={open}
                onClose={handleToggleDrawer}
                variant="temporary"
            >
                <FilterProduct />
            </Drawer>
        </Box>
    );
};


export default ShortFiltrer