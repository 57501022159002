import { useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../../assets/image/Union.png";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { FaRegBuilding } from "react-icons/fa";
import {
  PromoCard,
  PromoCardCard,
  PromoCardIconWrapper,
  PromoCardContentWrapper,
  PromoCardText,
} from "./Styles";
import { Typography } from "antd";
import { Container, Modal } from "react-bootstrap";
import StepForm from "../ListYourproperty";
import Locations from "./Locations";
import { Box } from "@mui/material";
import TopBar from "./TopBar";
function NavbarHome() {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="mainHeader">
      <TopBar />
      <Container className="glass">
        <header>
          <Navbar expand="lg">
            <Navbar.Brand href="/">
              {" "}
              <img src={Logo} alt="Union" class="img-fluid" width={120} />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav justify-content-end;">
              <Nav className="ms-auto backgundnav-m ">
                <Nav.Link href="/hotels" className=" navbar_text fw-bolder">
                  Hotels
                </Nav.Link>
                <Nav.Link href="/services" className="navbar_text fw-bolder ">
                  Services
                </Nav.Link>
                <Nav.Link href="#link" className="navbar_text fw-bolder ">
                  Blog
                </Nav.Link>

                <Nav.Link href="#link" className="navbar_text fw-bolder ">
                  Events
                </Nav.Link>
                <Nav.Link href="/hotels" className="m-auto">
                  <button
                    style={{ width: "100px" }}
                    className="btn   btn-danger btn-sm pr-3"
                  >
                    Book Now
                  </button>
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </header>
      </Container>
    </div>
  );
}

export default NavbarHome;
