import { useState } from "react";
import { Container, Row, Col } from 'react-bootstrap';
import { FaPhone, FaEnvelope, FaSignInAlt, FaUser } from 'react-icons/fa';
import { AiFillFacebook, AiFillTwitterSquare, AiFillInstagram, AiFillLinkedin } from 'react-icons/ai';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
    PromoCard,
    PromoCardCard,
    PromoCardIconWrapper,
    PromoCardContentWrapper,
    PromoCardText,
} from "./Styles";
import StepForm from "../ListYourproperty";
import { FaRegBuilding } from "react-icons/fa";
import { Typography } from "antd";
import { Modal } from "react-bootstrap";
import { Grid } from "@mui/material";
import { useSelector } from "react-redux";
function TopBar() {
    const [open, setOpen] = useState(false);
    const { isLoggedIn, user } = useSelector(
        (state) => state.authSlice
    );
    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    return (
        <div className="top-header">
            <Modal show={open} onHide={handleClose}>
                <Modal.Header>
                    <Modal.Title>List your property </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <StepForm onCLose={handleClose} />
                </Modal.Body>
            </Modal>
            <Container>
                <Grid container spacing={1} className="align-items-center mt-1 ">
                    <Grid item lg={7} xs={7}>
                        <div className="top-header-left ">
                            <div className="top-contact-info">
                                <ul className="m-0">
                                    <li>
                                        <a href="tel: +91 9599085990">
                                            <FaPhone />
                                            +91 9599085990
                                        </a>
                                    </li>
                                    <li className="d-none d-md-inline">
                                        <a href="mailto:info@staykr.com">
                                            <FaEnvelope />
                                            <span className="__cf_email__">info@staykr.com</span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div className="top-social">
                                <a href="https://www.facebook.com/people/Staykr_official/100093470272242/" target="_blank">
                                    <AiFillFacebook />
                                </a>
                                <a href="https://twitter.com/staykr_Official" target="_blank">
                                    <AiFillTwitterSquare />
                                </a>
                                <a href="https://www.instagram.com/staykr_official/" target="_blank">
                                    <AiFillInstagram />
                                </a>
                                <a href="https://www.linkedin.com/" target="_blank">
                                    <AiFillLinkedin />
                                </a>
                            </div>
                        </div>
                    </Grid>
                    <Grid item lg={4} xs={4} className="pe-1 pe-md-2">
                        <div className="top-header-right">
                            {/* <div className="account">
                                <a className="" href="/user/login">
                                    <FaSignInAlt />
                                    <span className="d-none d-md-inline">Login</span>
                                </a>
                            </div> */}
                            <PromoCard onClick={handleOpen}>
                                <PromoCardCard>
                                    <PromoCardIconWrapper>
                                        <FaRegBuilding />
                                    </PromoCardIconWrapper>
                                    <PromoCardContentWrapper>
                                        <PromoCardText >
                                            List your property{" "}
                                        </PromoCardText>
                                        <Typography></Typography>
                                    </PromoCardContentWrapper>
                                </PromoCardCard>
                            </PromoCard>&nbsp;&nbsp;&nbsp;
                            <PromoCard >
                                <PromoCardCard>
                                    <PromoCardIconWrapper>
                                        <FaUser />
                                    </PromoCardIconWrapper>
                                    <PromoCardContentWrapper className="d-flex">
                                        <a href="/login" class="text-reset text-decoration-none">
                                            <PromoCardText>
                                                {isLoggedIn ? <span>{user?.name}</span> : <span>Login </span>}
                                            </PromoCardText>
                                        </a>
                                        <a href="/registration" class="text-reset text-decoration-none">
                                            <PromoCardText>
                                                {isLoggedIn ? <span>{user?.name}</span> : <span>/ Register </span>}
                                            </PromoCardText>
                                        </a>
                                        <Typography></Typography>
                                    </PromoCardContentWrapper>
                                </PromoCardCard>
                            </PromoCard>
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
}

export default TopBar;
