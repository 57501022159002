import React from 'react'
import BreadCrumb from '../BreadCrumb'
import { FooterTwo } from '../FooterTwo'
import { Typography } from '@mui/material'

const AboutUS = () => {
    return (
        <div>
            <BreadCrumb title={"About us"} />

            <div className='container pt-5 pb-5'>
                <p >

                    Welcome to our world of exceptional hotel experiences, welcome to StayKR. Unlike any other service, we prioritize Cleanliness, Family-Friendliness, and superb value, setting us apart in a crowded marketplace and positioning us as the preferred alternative to other hotel booking services like OYORooms. Our mission is to revolutionize the hotel booking industry in India, and we do this by ensuring quality, affordability, and a safe environment in every property we list.


                </p>
                <p >

                    StayKR journey began when we noticed a significant gap in the market. Many hotel booking services listed properties with questionable standards of cleanliness and safety, causing discomfort and dissatisfaction among tourists and mid-management travelers. We believed that everyone deserved better. Thus, we embarked on our mission to provide excellent hotel experiences at budget prices without compromising on quality or safety.
                </p>
                <p >

                    We stand for uncompromising cleanliness. Our team inspects each hotel every three days, ensuring a spotless environment that enhances your comfort and peace of mind. We understand that cleanliness is a non-negotiable aspect of a pleasant stay, and we deliver just that, every single time.</p>

                <p >

                    StayKR hotels are not just clean; they are family-friendly. We know that traveling with family, especially kids, demands a certain level of consideration. From kid-friendly menus to entertaining play zones, our listed properties offer an environment that the entire family can enjoy.</p>


                <p >
                    We are also deeply committed to safety. We understand the apprehensions associated with staying in a new place. That's why we equip our listed properties with modern security features, including CCTV, to provide a safe environment for our guests. We take pride in offering a safe haven away from home for tourists, mid-management travelers, and families alike.

                </p>
                <p >
                    Moreover, we understand the frustrations that can come with uncertain bookings. That's why we offer confirmed bookings with 24/7 customer support. When you book with us, you can rest assured knowing that your booking is secure and that our dedicated team is always ready to assist you.
                </p>

                <p >
                    Additionally, we know that small gestures can make a big difference. To enhance your stay and provide more value, we offer free snacks once every 24 hours. It's our way of saying thank you for choosing us and making your stay a little more delightful.</p>

                <p >
                    Moreover, we understand the frustrations that can come with uncertain bookings. That's why we offer confirmed bookings with 24/7 customer support. When you book with us, you can rest assured knowing that your booking is secure and that our dedicated team is always ready to assist you.
                </p>
                In a market flooded with options, we stand out through our commitment to quality, affordability, safety, and excellent customer service. Our goal is to redefine what budget hotel booking means in India by setting new standards of service and comfort.

                Experience the difference with StayKR, where we combine comfort and convenience with affordability. We promise you a hotel stay that doesn't break the bank but still delivers the quality and comfort you deserve. Choose us for your next journey and discover why we are fast becoming the preferred choice for discerning travelers.

                <br />

                <ol>
                    <li>	StayKR:  Cleanliness and Comfort within your Budget" </li>
                    <li>
                        Secure Your Blissful Stay with StayKR
                    </li>
                    <li>
                        Experience the StayKR Difference: Affordable Luxury for All
                    </li>
                    <li>
                        StayKR: Where Every Stay is a Home Away from Home
                    </li>
                    <li>
                        Your Family-friendly Stay Begins at StayKR
                    </li>

                </ol>



            </div>


            <FooterTwo />


        </div>
    )
}

export default AboutUS