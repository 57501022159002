import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getAllProduct } from "../../baseApi/Api";

export const fetchProducts = createAsyncThunk(
    "product/fetchProducts",
    async () => {
        try {
            const response = await getAllProduct(); // Replace with your actual API endpoint to fetch products
            const products = response.data;

            return products;
        } catch (error) {
            throw Error("Failed to fetch products");
        }
    }
);



const initialState = {
    products: [],
    isLoading: false,
    error: null,
};

const productSlice = createSlice({
    name: "product",
    initialState,
    reducers: {
        sellAllProducts: (state) => {
            // Simulate selling all products
            state.products.forEach((product) => {
                product.isSold = true;
            });
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchProducts.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(fetchProducts.fulfilled, (state, action) => {
                state.isLoading = false;
                state.error = null;
                state.products = action.payload;
            })
            .addCase(fetchProducts.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message;
            });
    },
});

export const { sellAllProducts } = productSlice.actions;

export const initializeProducts = () => (dispatch) => {
    dispatch(fetchProducts());
    // Any other initialization logic you want to perform
};

export default productSlice.reducer;
