import React from 'react'
import { FaEnvelope, FaPhone, FaUserAlt } from 'react-icons/fa'
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
const Dashboard = () => {
    const { isLoggedIn, user } = useSelector(
        (state) => state.authSlice
    );
    if (!isLoggedIn) {
        return <Redirect to="/login" />;
    };
    return (
        <div> <div className="row">
            <div className="col-md-8">
                <h5 className="text-center text-md-start fw-bold">
                    Welcome to Customer Dashboard
                </h5>
                <div className="col-md-12">
                    <div className="fs-14 alert alert-secondary py-1 mb-2 w-100">
                        <span>
                            <FaUserAlt />&nbsp;
                            {user?.name}
                        </span>
                    </div>
                </div>

                <div className="col-md-9">
                    <div className="fs-14 alert alert-secondary py-1 mb-2 w-100">
                        <span>
                            <FaEnvelope />&nbsp;
                            {user?.email}
                        </span>
                    </div>
                </div>

                <div className="col-md-6">
                    <div className="fs-14 alert alert-secondary py-1 mb-2 w-100">
                        <span>
                            <FaPhone ></FaPhone>&nbsp;
                            {user?.mobile}
                        </span>
                    </div>
                </div>
            </div>
        </div></div>
    )
}

export default Dashboard