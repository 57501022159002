import React from 'react'
import BreadCrumb from './BreadCrumb'
import { FooterTwo } from './FooterTwo'

const HotelPolicy = () => {
    return (
        <div>
            <BreadCrumb title="Hotel Policy" />
            <div class="wrapper mt-5">
                <div class="page">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-12">
                                <p>Welcome to stakr!</p>
                                <p>Check-in time is 11:00 AM and check-out time is 10:00 PM noon. Subject to availability, early check-in and late check-out will be considered.</p>
                                <p>Extra Person charges – Rs. 350 per person.</p>
                                <p>100 % advance Payment deposit at the time of Check in.</p>
                                <p>
                                    It is Mandatory for all customers and their visitor guests to share their ID cards. Foreign Nationals are required to present their passport and valid visa. </p>
                                <p>We do not allow visitors entry to guest room.</p>
                                <p>      We accept advance booking only upto 1 month with full tariff advance payment.
                                    Personal food and beverages are not allowed.
                                </p>
                                <p>Accommodation will only be provided to guestswhose details are registered with hotel reception.</p>
                                <ul>
                                    <p>     1. Guests will be provided with a room key only upon arrival and after completing the registration at the hotel’s reception desk.
                                        <br />
                                        2. Due to the fact that the hotel is located in a residential area, it is required that guests, particularly those in groups, are respectful in regards to noise during the evenings.
                                        <br />
                                        3. Please note that the check-in and key collection take place at the hotel reception only.
                                        <br />
                                        4. Guests are required to show a photo identification upon check-in. Please note that all Special Requests are subject to availability, and additional charges may apply.
                                        <br />
                                        5. It is forbidden to bring flammable materials, explosives, or firearms into the hotel. Bringing or storing any products/items of combustible or hazardous nature, prohibited goods, or goods of objectionable nature is strictly prohibited.
                                        <br />
                                        6. Subject to the availability of storage space, guests can store their luggage in the luggage room at their own sole risk as to loss or damage from any cause. Luggage may not be stored for a period of over 24 hours.
                                        <br />
                                        7. Gambling, smoking, contraband/smuggled goods, prostitution, weapons, explosives, flammable objects, poisons, drugs, animals, non-vegetarian, and/or pungent food are all strictly prohibited on the hotel premises.
                                        <br />
                                        8. For any damage done to the hotel amenities, articles, furniture, property, etc., by guests themselves, their visitors, or any other person for whom they are responsible, guests will be held responsible and must pay and settle for such loss and damage in full as deemed appropriate by the management.
                                        <br />
                                        9. Visitors' entry to guests' rooms is strictly prohibited. However, in exceptional circumstances, the management may allow visitors to the guest room only after having photo identity proof documents and registering their entry at the reception. The customer's signed approval and mandatory registration at the reception desk, leaving an identity card and personal details, are required.
                                        <br />
                                        10. The hotel may deny further accommodation to a guest who does not prove to be decent and comply with the hotel's policy and rules.
                                        <br />
                                        11. Guests are not allowed to cause any disturbance to other guests or cause any nuisance or annoyance on the hotel premises. It is expected that guests will conduct themselves in a respectable, civilized, and decent manner.
                                        <br />
                                        12. Guests are liable to make up for any damage done to the hotel amenities, items, or property, and they will be charged an amount deemed appropriate by the management.
                                        <br />
                                        13. The management will not be responsible for any loss or damage to the guest's belongings for any cause whatsoever.
                                        <br />
                                        14. The guest shall be solely liable and responsible to the management, its other customers, guests, invitees, visitors, agents, and servants for any financial or other loss and damage that may be caused as a result of the guest's own negligence and non-observance of any hotel rules, policies, or instructions.
                                        <br />
                                        15. Using photographs and videos taken in the hotel for commercial or public purposes is illegal. Those who do so will be subject to prosecution.
                                        <br />
                                        16. The management has the right to request any guest to vacate their room or other areas of the hotel forthwith, without previous notice and without assigning any reason whatsoever, and the guest shall be bound to vacate when requested to do so. In case of default, the management has the right to remove the guest's luggage and belongings from the occupied room.
                                        <br />
                                        17. Guests are requested to observe, abide by, and be bound by all applicable acts, laws, government rules, and regulations in force from time to time.
                                        <br />
                                        18. The management reserves the right to add, alter, or amend any of the above policies, terms, conditions, and rules at any point in time without prior notice.
                                        <br />

                                    </p>
                                </ul>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FooterTwo />
        </div>
    )
}

export default HotelPolicy