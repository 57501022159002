import styled from "styled-components";
import { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { v4 as uuidv4 } from "uuid";

const Div = styled.div`
  display: flex;
  height: 13rem;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const LDiv = styled.div`
  width: 80%;
  height: 100%;
  & img {
    width: 100%;
    height: 13rem;
    margin: 0.5%;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`;
const RDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 20%;
  margin: 0.5%;
  overflow: hidden;
  height: 100%;
  @media (max-width: 768px) {
    flex-direction: row;
  }
  & img {
    width: 100%;
    height: 25%;
    margin: 0.5%;
  }
`;

export const ImageCarousel = ({ data, onClick }) => {
  const [img, setImg] = useState(0);
  console.log(img);
  const [slider, setSlider] = useState(null);
  const settings = {
    dots: false,
    arrows: true,
    fade: false,
    infinite: true,
    autoplay: false,
    speed: 800,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: (currentSlide) => {
      setImg(currentSlide);
    },
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handleImg = (index) => {
    // console.log(e.target.src);
    slider?.slickGoTo(index);
  };
  return (
    <Div>
      <LDiv>
        <Slider
          {...settings}
          className="h-100 w-100"
          ref={(slider) => setSlider(slider)}
        >
          {data?.map((item, index) => {
            return (
              <img
                style={{
                  cursor: "pointer",
                  maxWidth: "100%",
                  maxHeight: "100%",
                }}
                onClick={onClick}
                key={uuidv4()}
                className="image-fluid cursor-pointer"
                src={item?.image}
                alt=""
              />
            );
          })}
        </Slider>
      </LDiv>
      <RDiv>
        {data?.slice(0, 4).map((image, index) => (
          <img
            style={{ cursor: "pointer" }}
            key={uuidv4()}
            src={image?.image}
            alt=""
            onClick={() => handleImg(index)}
          />
        ))}
      </RDiv>
    </Div>
  );
};
