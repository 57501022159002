import React from 'react'
import { Container, Row, Col, Card, Image } from 'react-bootstrap';
import { FooterTwo } from '../FooterTwo';
import BreadCrumb from '../BreadCrumb';
const HotelServices = () => {
    const services = [
        {
            title: 'Hotel Accommodation',
            image: "/Images/services1.webp",
            description: 'Experience comfort and luxury in our exquisite hotel accommodations. Whether you\'re on a business trip or enjoying a leisurely vacation, our well-appointed rooms offer a perfect blend of modern amenities and serene ambiance. Enjoy personalized services and a relaxing environment throughout your stay.',
        },
        {
            title: 'Banquet Halls',
            image: "/Images/banquet.jpg",
            description: 'Host your special events in our spacious and elegant banquet halls. From weddings and engagement parties to corporate gatherings and social functions, our versatile banquet halls can accommodate any event. Our dedicated event coordinators will work closely with you to ensure every detail is taken care of, making your event a resounding success.',
        },
        {
            title: 'Conference Hall',
            image: "/Images/conference.jpg",
            description: 'Conduct successful business meetings, conferences, and seminars in our state-of-the-art conference hall. Equipped with modern audio-visual facilities and comfortable seating, our conference hall provides a professional setting for your corporate events. Our team will assist you with all technical arrangements, leaving you free to focus on your business objectives.',
        },
        {
            title: 'Paying Guest',
            image: "/Images/conference.jpg",
            description: 'For those seeking a home away from home, our paying guest accommodations offer a warm and welcoming environment. Ideal for students and working professionals, our PG services provide a safe and comfortable living space with all essential amenities. Experience a sense of community and convenience during your stay with us.',
        },
        {
            title: 'Events Planning',
            image: "/Images/event-planner.jpg",
            description: 'Let our expert event planners turn your vision into reality. Whether it\'s a grand celebration or an intimate gathering, our team will handle every aspect of event planning, including venue selection, decorations, catering, entertainment, and more. Sit back and enjoy your event while we take care of all the arrangements.',
        },
        {
            title: 'Wedding Ceremony Creation',
            image: "/Images/Ceremony.jpg",
            description: 'Your dream wedding starts here. Our wedding ceremony creation service is designed to make your special day truly magical. From crafting enchanting themes and décor to organizing seamless ceremonies, our wedding planners will create an unforgettable experience for you and your guests. Celebrate love with Staykr Services.',
        },
    ];
    return (
        <>
            <BreadCrumb title="Welcome to Staykr Services" />
            <Container>

                <Row className='mt-3'>
                    <Col>
                        <h2>Our Services</h2>
                        <Row>
                            {services.map((service, index) => (
                                <Col key={index} lg={4} className="mb-4 p-0">
                                    <Card className='m-1'>
                                        {service.image && (
                                            <Image src={service.image} alt={service.title} className="card-img-top" />
                                        )}
                                        <Card.Body>
                                            <Card.Title>{service.title}</Card.Title>
                                            <Card.Text>{service.description}</Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                    </Col>
                </Row>
            </Container>
            <FooterTwo />

        </>

    )
}

export default HotelServices