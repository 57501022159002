import React, { useState } from 'react'
import BreadCrumb from '../BreadCrumb'
import { FooterTwo } from '../FooterTwo'
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Button, TextField, Alert, AlertTitle, InputAdornment, IconButton, CircularProgress, OutlinedInput } from '@mui/material';
import Logo from "../../assets/image/Union.png";
import { signupUserAsync } from '../../store/slice/authSlice';
import { FaEyeSlash, FaEye } from "react-icons/fa";
import { Link } from 'react-router-dom';
const Registration = () => {
    const dispatch = useDispatch()
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const { isLoggedIn, isLoading, error, isRegistered } = useSelector(
        (state) => state.authSlice
    );
    const initialValues = {
        firstname: "",
        lastname: "",
        mobileno: "",
        email: "",
        password: "",
        confirmPassword: "",
        acceptTerms: false,
    };

    const validationSchema = Yup.object().shape({
        firstname: Yup.string()
            .required("First name is required")
            .trim()
            .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed")
            .min(3, "Must be at least 3 characters")
            .max(50, "Must not exceed 50 characters"),
        lastname: Yup.string()
            .required("Last name is required")
            .trim()
            .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed")
            .min(3, "Must be at least 3 characters")
            .max(50, "Must not exceed 50 characters"),
        mobileno: Yup.string()
            .required("Mobile number is required")
            .matches(/^[0-9]+$/, "Must be only digits")
            .min(10, "Must be exactly 10 digits")
            .max(10, "Must be exactly 10 digits"),
        email: Yup.string()
            .required("Email address is required")
            // .email("Email address is invalid"),
            .test("test-name", "Enter Valid Email", function (value) {
                const emailRegex =
                    /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
                // eslint-disable-next-line
                let isValidEmail = emailRegex.test(value);
                if (!isValidEmail) {
                    return false;
                }
                return true;
            }),
        password: Yup.string()
            .required("Password is required")
            .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                "Must be min 8 chars with one upper, lower, number & special char"
            )
            .max(20, "Password must not exceed 20 characters"),
        confirmPassword: Yup.string()
            .required("Confirm Password is required")
            .oneOf([Yup.ref("password"), null], "Confirm Password does not match"),
        // acceptTerms: Yup.bool().oneOf([true], "Accept Ts & Cs is required"),
    });

    const onSubmit = (values) => {
        console.log(values);
        let data = {
            firstname: values?.firstname,
            lastname: values?.lastname,
            mobile: values?.mobileno,
            email: values.email,
            password: values?.password,
            gender: ""
        }
        // Perform login logic here
        dispatch(signupUserAsync(data))
    };

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit,
    });

    // if (isRegistered) {
    //     return <Redirect to="/login" />;
    // };
    return (
        <>

            <BreadCrumb title="Registration" />
            <div className="login-area pt-120 pb-100">
                <div className="container p-3">
                    <div className="col-md-5 mx-auto">
                        <div className="login-form">
                            <div className="login-header">
                                <img
                                    src={Logo}
                                    alt=""
                                />

                                <p>Registration </p>
                            </div>
                            {isRegistered ? (
                                <Alert variant="filled" severity="success">
                                    Registration complete! Welcome aboard! <Link to="/login" className='text-white text-decoration-none'> Click Here to Login</Link> now and enjoy!
                                </Alert>
                            ) : (
                                <>


                                    {error && (
                                        <Alert variant="filled" severity="error">
                                            {error}
                                        </Alert>
                                    )}

                                    <form onSubmit={formik.handleSubmit} autocomplete="off">
                                        <div className=" d-flex">
                                            <TextField
                                                id="firstname"
                                                name="firstname"
                                                label="First Name"
                                                autoComplete={false}
                                                type="text"
                                                size='small'
                                                value={formik.values.firstname}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                error={formik.touched.firstname && Boolean(formik.errors.firstname)}
                                                helperText={formik.touched.firstname && formik.errors.firstname}
                                                margin="normal"
                                            />&nbsp;
                                            <TextField
                                                id="lastname"
                                                name="lastname"
                                                label="Last Name"
                                                autoComplete={false}
                                                type="text"
                                                size='small'
                                                value={formik.values.lastname}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                error={formik.touched.lastname && Boolean(formik.errors.lastname)}
                                                helperText={formik.touched.lastname && formik.errors.lastname}
                                                margin="normal"
                                            />

                                        </div>
                                        <TextField
                                            id="email"
                                            name="email"
                                            label="Email" autocomplete="off"
                                            type="email"
                                            fullWidth
                                            size='small'
                                            value={formik.values.email}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.email && Boolean(formik.errors.email)}
                                            helperText={formik.touched.email && formik.errors.email}
                                            margin="normal"
                                        />

                                        <TextField
                                            id="mobileno"
                                            name="mobileno"
                                            label="Phone Number"
                                            autocomplete="off" type="tel"
                                            size='small'
                                            fullWidth
                                            value={formik.values.mobileno}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.mobileno && Boolean(formik.errors.mobileno)}
                                            helperText={formik.touched.mobileno && formik.errors.mobileno}
                                            margin="normal"
                                        />

                                        <OutlinedInput
                                            id="password"
                                            name="password"
                                            label="Password"
                                            placeholder='Password'
                                            type={showPassword ? 'text' : 'password'}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        edge="end"
                                                        size='small'
                                                    >
                                                        {showPassword ? <FaEye /> : <FaEyeSlash />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            fullWidth
                                            size='small'
                                            autocomplete="off"
                                            value={formik.values.password}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.password && Boolean(formik.errors.password)}
                                            helperText={formik.touched.password && formik.errors.password}
                                            margin="normal"
                                        />

                                        <TextField
                                            id="confirmPassword"
                                            name="confirmPassword"
                                            label="Confirm Password"
                                            type="password"
                                            fullWidth
                                            size='small'
                                            autocomplete="off"
                                            value={formik.values.confirmPassword}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
                                            helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
                                            margin="normal"
                                        />
                                        <div className="d-flex justify-content-between mb-4">
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    value={formik.values.acceptTerms}
                                                    error={formik.touched.acceptTerms && Boolean(formik.errors.acceptTerms)}
                                                    id="acceptTerms"
                                                    name="acceptTerms"
                                                    onChange={formik.handleChange}
                                                />
                                                <label
                                                    className="form-check-label"
                                                    htmlFor="remember"
                                                >
                                                    Remember Me
                                                </label>
                                            </div>

                                        </div>
                                        <Button type="submit" disabled={isLoading} fullWidth variant="contained" color="primary">
                                            Registration {isLoading && (<CircularProgress size={20} />)}
                                        </Button>
                                    </form>
                                    <p>
                                        I'm already a member!{" "}
                                        <Link href="/login">
                                            Login
                                        </Link>
                                    </p>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <FooterTwo />

        </>
    )
}

export default Registration