


export const Offer1 = () => {
  return (
    // <Div>
    //   <h5>
    //     Earn <span>₹500</span>
    //   </h5>
    //   <p>Create an account to get ₹500 as STAYKR Money</p>
    // </Div>
    // <Img src="Images/Offer1.png" alt="" />
    <></>
  );
};
