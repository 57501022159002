import { SliderMain } from "./Slider";
import "./RoomDetails.css";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { hotels } from "../db";
import moment from "moment";
import "moment/locale/en-au";
import { useState, useEffect } from "react";
import { FooterTwo } from "../FooterTwo";
import { Box, Grid, IconButton, CircularProgress } from "@mui/material";
import { Row, Col, Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import ReactImageZoom from "react-image-zoom";
import { Card } from "react-bootstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Typography } from "@mui/material";
import {
  BsFillAlarmFill,
  BsFillCameraVideoFill,
  BsFillChatFill,
} from "react-icons/bs";
import { FaTv, FaWifi, FaTemperatureHigh } from "react-icons/fa";
import Star from "../Star";
import {
  setCity,
  setStartDate,
  setEndDate,
  setRooms,
  setGuests,
} from "../../store/slice/searchslice";
import BestHotal from "../Home/BestHotal";
import BreadCrumb from "../BreadCrumb";
import { getProductById } from "../../baseApi/Api";
import DescriptionsRef from "./DescriptionsRef";
import { MdLocationPin } from "react-icons/md";
import PriceSection from "../Pricesection";
import { useDispatch } from "react-redux";

export function RoomDetails() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [pageData, setPageData] = useState(null);
  const [pageImg, setPageImg] = useState([]);
  const [imageOpen, setImageOpen] = useState(null);

  const [finalPrice, setFinalPrice] = useState(1200);
  const [isLoading, setLoading] = useState(false);
  const settings = {
    dots: false,
    arrows: true,
    fade: false,
    infinite: true,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  useEffect(() => {
    getProductInfoById(id);
    dispatch(setRooms(1));
    dispatch(setGuests(1));
    dispatch(setStartDate(null));
    dispatch(setEndDate(null));
    // eslint-disable-next-line
  }, [id]);

  const getProductInfoById = (id) => {
    setLoading(true);
    getProductById(id)
      .then((response) => {
        // alert("fsdf");
        setLoading(false);
        console.log("foundHotel ", response?.data);
        const hotelData = response?.data;

        if (hotelData) {
          const images = hotelData?.hotelBasePrice[0]?.images;
          setPageImg(images);
          console.log(hotels.hotel);
          console.log("foundHotel ", hotelData);
          setFinalPrice(Number(hotelData?.hotelBasePrice[0]?.base_price));
          setPageData(hotelData);
        }
      })
      .catch((e) => {
        //console.log(e)
        setLoading(false);
      });
  };

  return (
    <div>
      {/* <NavbarSticky /> */}
      {/* {pageData.map((e) => { */}
      <BreadCrumb title={pageData?.hotelName} />
      <div className="container mt-5 p-3 mb-3  small ">
        <Grid
          container
          spacing={1}
          className="flex--sm-row flex-xs-column-reverse "
        >
          <Grid
            item
            sm={6}
            lg={6}
            xs={12}
            className="p-2  m-auto"
            sx={{ marginTop: "5rem" }}
          >
            {isLoading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <SliderMain arr={pageImg || []} setImageOpen={setImageOpen} />
            )}

            <div className="product-details-child-left-div p-2">
              <h1>
                {pageData?.hotelName}{" "}
                <span>
                  {" "}
                  <Star stars={pageData?.rating || 4} />
                </span>
                <p> (300 Ratings) . Good</p>
              </h1>
              <p className="located">Located in {pageData?.City}</p>
              <h5 className="  mb-2 fw-bold">
                {pageData?.address}{" "}
                <span>
                  {" "}
                  <IconButton
                    onClick={() => {
                      const address = pageData?.address;
                      const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                        address
                      )}`;
                      window.open(googleMapsUrl);
                    }}
                    size="sm"
                    variant="outlined"
                    sx={{ color: "red" }}
                  >
                    <MdLocationPin />
                  </IconButton>
                </span>
              </h5>

              <h5 className="mb-2 fw-bold">Description</h5>

              <DescriptionsRef
                htmlContent={pageData?.hotelBasePrice[0]?.description}
              />
              <h5 className="fw-bold mt-3 mb-3">Amenities</h5>
              <div className="mb-2">
                <Row>
                  <Col xs={4}>
                    <div className="text-center text-gray">
                      <BsFillAlarmFill size={15} />
                      <p>Alarm</p>
                    </div>
                  </Col>
                  <Col xs={4}>
                    <div className="text-center text-gray">
                      <BsFillCameraVideoFill size={15} />
                      <p>Camera</p>
                    </div>
                  </Col>
                  <Col xs={4}>
                    <div className="text-center text-gray">
                      <BsFillChatFill size={15} />
                      <p>Chat</p>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="mb-2">
                <Grid container spacing={1}>
                  <Grid item xs={4}>
                    <div className="text-center text-gray">
                      <FaTv size={15} />
                      <p>TV</p>
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <div className="text-center text-gray">
                      <FaTemperatureHigh size={15} />
                      <p>AC</p>
                    </div>
                  </Grid>
                  <Grid xs={4}>
                    <div className="text-center text-gray">
                      <FaWifi size={15} />
                      <p>Wifi</p>
                    </div>
                  </Grid>
                </Grid>
              </div>
              <div>
                <h3 className="fw-bold mt-3 mb-3">Hotel Policies</h3>
                <div className="flex">
                  <div>
                    <Button size="sm" variant="outline-primary">
                      Check in :11.00 AM
                    </Button>
                  </div>
                  &nbsp;
                  <div>
                    <Button size="sm" variant="outline-primary">
                      Check Out :10.00 AM
                    </Button>
                  </div>
                </div>
                <ul>
                  <li>Extra Person charges – Rs. 350 per person.</li>
                  <li>
                    100 % advance Payment deposit at the time of Check in.
                  </li>
                  <li>
                    It is Mandatory for all customers and their visitor guests
                    to share their ID cards. Foreign Nationals are required to
                    present their passport and valid visa.
                  </li>
                  <li>
                    We accept advance booking only upto 1 month with full tariff
                    advance payment. Personal food and beverages are not
                    allowed. <Link to="/hotel-policy">Read More</Link>
                  </li>
                </ul>
              </div>
            </div>
          </Grid>
          <Grid item lg={4} sm={4} xs={12}>
            <div className="product-details-child-right-div">
              <div className="detail-page-login flex">
                <Link to="/login" className="text-decoration-none text-white">
                  {" "}
                  <Typography variant="p">
                    CLICKED HERE TO EXCLUSIVE DEALS WITH YOUR LOGIN
                  </Typography>
                </Link>
              </div>
              <Box style={{ padding: "16px 24px" }}>
                <div className=" price ">
                  <div className="fs-5 fw-bold">₹ {finalPrice} / Night </div>
                  <p>
                    <strong>inclusive of all taxes</strong>
                  </p>
                </div>
                {pageData?.hotelBasePrice[0]?.id ? (
                  <PriceSection
                    roomId={pageData?.hotelBasePrice[0]?.id}
                    finalPrice={finalPrice}
                  />
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CircularProgress />
                  </div>
                )}

                <Link
                  onClick={() => {
                    window.scrollTo({
                      top: 0, // Optionally, use 'auto' or 'smooth' for smooth scrolling
                    });
                  }}
                  to={`/payment/${pageData?.hotel_id}`}
                >
                  <button
                    style={{ background: "#1ab64f" }}
                    className="btn btn-sm w-100 btn-success"
                  >
                    Continue to Book
                  </button>
                </Link>
                <div className="redFont">
                  <p>
                    <Link to="/hotel-policy">Cancellation Policy</Link>
                  </p>
                  <p>
                    <Link to="/terms-and-consitions">Terms & Conditions</Link>
                  </p>
                  <p>
                    <Link to="/privacy-policy">
                      By proceeding, you agree to our Guest Policies.
                    </Link>
                  </p>
                </div>
              </Box>
            </div>
          </Grid>
        </Grid>
      </div>
      <BestHotal title={"Similar Hotels"} />
      <FooterTwo></FooterTwo>
      {imageOpen && (
        <Modal
          dialogClassName="modal-60w"
          backdrop="static"
          animation={false}
          centered
          show={true}
          onHide={() => setImageOpen(null)}
        >
          <Modal.Header
            className="m-0 p-0 text-right"
            style={{
              justifyContent: "end",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Button
              className="ml-auto btn-rounded"
              size="sm"
              variant="light"
              onClick={() => setImageOpen(null)}
            >
              &#10006;
            </Button>
          </Modal.Header>
          <Modal.Body>
            {" "}
            <Slider {...settings} className="h-100 w-100">
              {pageImg.map((item, index) => {
                return (
                  <Card className="m-2 rounded">
                    {" "}
                    <Card.Img
                      src={item?.image}
                      alt="no"
                      style={{
                        cursor: "pointer",
                        maxHeight: "450px",
                        aspectRatio: "16/16",
                      }}
                      className="rounded h-100"
                    />{" "}
                  </Card>
                );
              })}
            </Slider>
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
}
