import React from "react";
import {
    Grid,
    TextField,
    FormHelperText,
    InputAdornment,
    MenuItem, FormControl, InputLabel, Select
} from "@mui/material";
import { useDropzone } from 'react-dropzone';
import { FaDropbox } from "react-icons/fa";
import { useSelector } from "react-redux";

const AccountDetails = (props) => {
    const { formik } = props;
    const { cityList } = useSelector(
        (state) => state.searchslice
    );
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <TextField
                    name="hotelName"
                    label="Hotel Name"
                    variant="outlined"
                    type="text"
                    fullWidth
                    size="small"
                    error={formik?.touched?.hotelName && Boolean(formik?.errors?.hotelName)}
                    onChange={formik?.handleChange}
                    value={formik?.values?.hotelName}
                />
                {formik?.touched?.hotelName && formik?.errors?.hotelName && (
                    <FormHelperText error>{formik?.errors?.hotelName}</FormHelperText>
                )}
            </Grid>
            <Grid item xs={12}>
                <TextField
                    name="hotelPhone"
                    label="Hotel Phone Number"
                    variant="outlined"
                    size="small"
                    type="tel"
                    fullWidth
                    error={formik?.touched?.hotelPhone && Boolean(formik?.errors?.hotelPhone)}
                    onChange={formik?.handleChange}
                    value={formik?.values?.hotelPhone}
                />
                {formik?.touched?.hotelPhone && formik?.errors?.hotelPhone && (
                    <FormHelperText error>{formik?.errors?.hotelPhone}</FormHelperText>
                )}
            </Grid>
            <Grid item xs={12}>
                <TextField
                    name="hotalLocation"
                    label="Hotal Location"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    error={formik?.touched?.hotalLocation && Boolean(formik?.errors?.hotalLocation)}
                    onChange={formik?.handleChange}
                    value={formik?.values?.hotalLocation}
                />
                {formik?.touched?.hotalLocation && formik?.errors?.hotalLocation && (
                    <FormHelperText error>{formik.errors.hotalLocation}</FormHelperText>
                )}
            </Grid>
            <Grid item xs={12}>
                <FormControl variant="outlined" size="small" fullWidth>
                    <TextField
                        name="hotalLocation"
                        label="Hotal Area"
                        variant="outlined"
                        size="small"
                        type="text"
                        fullWidth
                        error={formik?.touched?.city && Boolean(formik?.errors?.city)}
                        onChange={formik?.handleChange}
                        value={formik?.values?.city}
                    />
                    {formik?.touched?.city && formik?.errors?.city && (
                        <FormHelperText error>{formik.errors.city}</FormHelperText>
                    )}
                </FormControl>

            </Grid>
            {/* <Grid item xs={12}>
                <TextField
                    name="country"
                    label="Country"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    error={formik.touched.country && Boolean(formik.errors.country)}
                    onChange={formik.handleChange}
                    value={formik.values.country}
                />
                {formik.touched.country && formik.errors.country && (
                    <FormHelperText error>{formik.errors.country}</FormHelperText>
                )}
            </Grid> */}

            <Grid item xs={12}>
                <InputLabel htmlFor="document">Select Photos</InputLabel>
                <FormControl variant="outlined" size="small" fullWidth>
                    <TextField
                        name="hotelPhotes"
                        label=""
                        variant="outlined"
                        size="small"
                        type="file"
                        inputProps={{
                            accept: 'image/*',
                        }}
                        fullWidth
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <FaDropbox />
                                </InputAdornment>
                            ),
                        }}
                        error={formik?.touched?.hotelPhotes && Boolean(formik?.errors?.hotelPhotes)}
                        onChange={formik?.handleChange}
                        value={formik?.values.hotelPhotes || undefined}
                    />
                    {formik?.touched?.hotelPhotes && formik?.errors?.hotelPhotes && (
                        <FormHelperText error>{formik?.errors?.hotelPhotes}</FormHelperText>
                    )}
                </FormControl>
            </Grid>
            {/* <Grid item xs={12}>
                <TextField
                    name="hotelextraService"
                    label="Hotel Extra Service"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth

                    error={formik.touched.hotelextraService && Boolean(formik.errors.hotelextraService)}
                    onChange={formik.handleChange}
                    value={formik.values.hotelextraService}
                />
                {formik.touched.hotelextraService && formik.errors.hotelextraService && (
                    <FormHelperText error>{formik.errors.hotelextraService}</FormHelperText>
                )}
            </Grid>
            <Grid item xs={12}>
                <TextField
                    name="hotelBasePrice"
                    label="Hotel Base Price"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    error={formik.touched.hotelBasePrice && Boolean(formik.errors.hotelBasePrice)}
                    onChange={formik.handleChange}
                    value={formik.values.hotelBasePrice}
                />
                {formik.touched.hotelBasePrice && formik.errors.hotelBasePrice && (
                    <FormHelperText error>{formik.errors.hotelBasePrice}</FormHelperText>
                )}
            </Grid> */}
            {formik?.errors?.submit && (
                <Grid item xs={12}>
                    <FormHelperText error>{formik?.errors?.submit}</FormHelperText>
                </Grid>
            )}
        </Grid>
    );
};

export default AccountDetails;
