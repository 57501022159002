import { Route, Switch } from "react-router-dom";
import { PaymentMain } from "../Components/Payment/PaymentMain";
import HomeMain from "../Components/Home/HomeMain";
import { RoomDetails } from "../Components/RoomDetail/RoomDetails";
import { HotelOptions } from "../Components/HotelOptions";
import Login from "../Components/auth/Login";
import Registration from "../Components/auth/Registration";
import TermsCondition from "../Components/TermsCondition";
import PrivicyPolicy from "../Components/PrivicyPolicy";
import HotelPolicy from "../Components/HotelPolicy";
import Locations from "../Components/Home/Locations";
import Profile from "../Components/user/Profile";
import AboutUS from "../Components/Aboutus";
import HotelServices from "../Components/hotelHotels";

export function RouteMain() {
  return (
    <div>
      <Switch>
        <Route path="/" exact>
          <HomeMain />
        </Route>
        <Route path="/hotels">
          <HotelOptions />
        </Route>
        <Route path="/hoteldetail/:id">
          <RoomDetails />
        </Route>
        <Route path="/contact">Contact Page</Route>
        <Route path="/login">
          <Login />
        </Route>
        <Route path="/registration">
          <Registration />
        </Route>
        <Route path="/payment/:id">
          <PaymentMain />
        </Route>
        <Route path="/services">
          <HotelServices />
        </Route>
        <Route path="/terms-and-consitions">
          <TermsCondition />
        </Route>
        <Route path="/privacy-policy">
          <PrivicyPolicy />
        </Route>
        <Route path="/hotel-policy">
          <HotelPolicy />
        </Route>
        <Route path="/hotel-locations">
          <Locations />
        </Route>
        <Route path="/user">
          <Profile />
        </Route>
        <Route path="/about-us">
          <AboutUS />
        </Route>
        <Route>
          <h1 style={{ textAlign: "center" }}>404 Not Found</h1>
        </Route>
      </Switch>
    </div>
  );
}
