import { FaArrowRight } from "react-icons/fa";
import NavbarHome from "../Home/NavbarHome";

const BreadCrumb = ({ title }) => {
    return (
        <>
            <NavbarHome />
            <div
                className="site-breadcrumb"
                style={{
                    backgroundImage:
                        "url('https://hotelwedlock.com/public/frontend/assets/img/bg/breadcrumb.jpg')",
                }}
            >
                <div className="container">
                    <h4 class="breadcrumb-title">{title}</h4>
                    <ul class="breadcrumb-menu">
                        <li>
                            <a class="" href="/">
                                Home
                            </a>
                        </li>
                        <li ><FaArrowRight /> </li>
                        <li class="active">{title}</li>
                    </ul>
                </div>
            </div>
        </>

    );
}

export default BreadCrumb
