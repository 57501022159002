import React from 'react'
import { useRef, useState, useEffect } from "react";
import { DatePicker } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { Typography, Box, } from "@mui/material";
import { Button, Popover, Space } from 'antd';

import moment from "moment";
import FinalPrice from './FinalPrice';
import {
    setCity,
    setStartDate,
    setEndDate,
    setRooms,
    setGuests,
} from "../../store/slice/searchslice";
const PriceSection = ({ finalPrice, roomId }) => {
    const { RangePicker } = DatePicker;
    const dispatch = useDispatch();
    const { startDate, endDate, rooms, guests } = useSelector(
        (state) => state.searchslice
    );
    const [roomsOpen, setrooms] = useState(null);
    console.log("roomId", roomId)

    const handleRoomDIv = (event) => {
        setrooms(event.currentTarget);
    };
    const handleClose = () => {
        setrooms(null);
    };
    const open = Boolean(roomsOpen);
    const id = open ? 'simple-popover' : undefined;


    // console.log(startDate);

    // console.log(pageData.images)


    const handleDateChange = (dates) => {
        dispatch(setStartDate(dates[0]));
        dispatch(setEndDate(dates[1]));
    };

    const disabledDate = (current) => {
        // Disable dates before today and after one month from today
        return current && current < moment().startOf("day");
    };

    return (
        <div><Box>
            {/* <div style={{ position: "relative", zIndex: 99 }}> */}
            <RangePicker
                value={[startDate, endDate]}
                onChange={handleDateChange}
                allowClear={false}
                showToday={false}
                format="DD-MM-YYYY"
                disabledDate={disabledDate}
                placeholder={["Check In", "Check Out"]}
                className="w-100"
                style={{ fontWeight: "bolder", borderColor: "#000000" }}

            />
            {/* </div> */}
        </Box>
            <div className='d-flex justify-content-between align-items-center mt-1 '>
                <div>
                    Room:
                </div>
                <div className="room-counter m-auto">

                    <button
                        onClick={() => {
                            dispatch(setRooms(rooms - 1));
                            dispatch(setGuests(1));
                        }}
                        disabled={rooms === 1}
                    >
                        -
                    </button>
                    <p>{rooms}</p>
                    <button
                        onClick={() => {
                            dispatch(setRooms(rooms + 1));
                        }}
                    >
                        +
                    </button>
                </div>

            </div>

            <div className='d-flex justify-content-between align-items-center '>
                <div>
                    Guest:

                </div>
                <div className="room-counter m-auto">
                    <button
                        onClick={() => {
                            dispatch(setGuests(guests - 1));
                        }}
                        disabled={guests === 1}
                    >
                        -
                    </button>
                    <p>{guests}</p>
                    <button
                        onClick={() => {
                            dispatch(setGuests(guests + 1));
                        }}
                        disabled={Boolean(rooms * 3 === guests)}
                    >
                        +
                    </button>
                </div>

            </div>


            <Box>
                <div>
                    <FinalPrice
                        startDate={startDate}
                        endDate={endDate}
                        roomCount={rooms}
                        guestCount={guests}
                        basePrice={finalPrice}
                        roomId={roomId}

                    />
                </div>
            </Box></div>
    )
}

export default PriceSection