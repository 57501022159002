import styled from "styled-components";
import {
  AiFillFacebook,
  AiFillTwitterSquare,
  AiFillInstagram,
  AiFillLinkedin,
} from "react-icons/ai";
import { Link } from "react-router-dom";
export const FooterTwo = () => {
  const Footer = styled.div`
    background-color: #6d787d;
    color: #ffffff;

    .footer-sec2 {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      gap: 20px;
      padding: 30px;
      border-bottom: 1px solid hsla(0, 0%, 100%, 0.5);
    }

    .footer-sec2 div {
      border: none;
    }

    .footer-sec2 div:nth-of-type(1) p {
      width: 100%;
      margin-bottom: 10px;
    }

    .footer-sec2 div:nth-of-type(2),
    .footer-sec2 div:nth-of-type(3) {
      width: 100%;
      border-right: none;
      border-left: none;
    }

    .footer-sec2 div:nth-of-type(2) p,
    .footer-sec2 div:nth-of-type(3) p {
      margin: 10px;
    }

    .footer-sec2 div:nth-of-type(2) p:hover,
    .footer-sec2 div:nth-of-type(3) p:hover {
      text-decoration: underline;
    }

    .footer-sec3 {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      text-align: center;
      padding: 35px;
      border-bottom: 1px solid hsla(0, 0%, 100%, 0.5);
    }

    .footer-sec3 h3 {
      color: #ffffff;
    }

    .footer-sec3 p {
      margin-top: 15px;
    }

    .footer-sec5 {
      padding: 35px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .footer-sec5 img {
      margin-right: 25px;
      cursor: pointer;
    }

    @media (max-width: 768px) {
      .footer-sec2 {
        grid-template-columns: none;
        display: block;
        gap: 0;
        width: 100%;
        padding: 1px;
      }

      .footer-sec2 div:nth-of-type(1) p {
        width: 100%;
      }

      .footer-sec2 div:nth-of-type(2),
      .footer-sec2 div:nth-of-type(3),
      .footer-sec2 div:nth-of-type(4) {
        width: 100%;
        border-right: none;
        border-left: none;
      }
    }
  `;

  return (
    <>
      <Footer>
        <div className="footer-sec2">
          <div>
            <p>Download STAYKR app for excitng offers.</p>
            <br />
            <div className="d-flex">
              <img width={130} src="/Images/appstore.png" alt="applestore" />

              <img width={130} src="/Images/playstored.png" alt="playstored" />
            </div>
          </div>
          <div>
            <Link className="text-white text-decoration-none" to="/about-us">
              <p>About Us</p>
            </Link>

            <p>Blogs</p>
            <p>Official STAYKR Blog</p>
            <p>STAYKR Circle</p>
          </div>
          <div>
            <p>Teams / Careers</p>
            <p>Support</p>
            <p>Press Kit</p>
            <p>STAYKR Frames</p>
          </div>
          <div>
            <Link
              className="text-white text-decoration-none"
              to="/terms-and-consitions"
            >
              <p>Terms and conditions</p>
            </Link>
            <Link
              className="text-white text-decoration-none"
              to="/hotel-policy"
            >
              <p>Guest Policies</p>
            </Link>
            <Link
              className="text-white text-decoration-none"
              to="/privacy-policy"
            >
              <p>Privacy Policy</p>
            </Link>
            <p>Responsible Disclosure</p>
          </div>
        </div>

        <div className="footer-sec5">
          <div className="top-social">
            <Link
              to="https://www.facebook.com/people/Staykr_official/100093470272242/"
              target="_blank"
            >
              <AiFillFacebook />
            </Link>
            <a to="https://twitter.com/staykr_Official" target="_blank">
              <AiFillTwitterSquare />
            </a>
            <Link
              to="https://www.instagram.com/staykr_official/"
              target="_blank"
            >
              <AiFillInstagram />
            </Link>
            <Link href="https://www.linkedin.com/" target="_blank">
              <AiFillLinkedin />
            </Link>
          </div>
        </div>
      </Footer>
    </>
  );
};
