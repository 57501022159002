import "./PaymentStyle.css";
import { PaymentDetail } from "./PaymentDetail";
import { PaymentCompleteClosed } from "./PaymentCompleteClosed";
import { PaymentSide } from "./PaymentSide";
import { PaymentCompleteOpen } from "./PaymentCompleteOpen";
import { PaymentInpDetail } from "./PaymentInpDetail";

import { useState, useEffect } from "react";
import { hotels } from "../db";
import { useParams, useHistory } from "react-router-dom";
import BreadCrumb from "../BreadCrumb";
import { getProductById } from "../../baseApi/Api";
import { FooterTwo } from "../FooterTwo";

var initVar = {
  name: "SPOT ON 46946 Hotel Aalishan",
  poster: "./Images/Payment/payaali1.jpg",
  days: "1",
  new: "true",
  date: "Thu, 14 Oct - Fri, 15 Oct",
  room: "1 Room, 2 Guests",
  type: "SPOT ON NON-AC",
  price: 30722,
  pdrop: 11684,
};

export function PaymentMain() {
  const history = useHistory();

  const [payData] = useState(initVar);

  const [confirm, setConfirm] = useState(false);

  const handleleave = () => {
    setConfirm(false);
    history.push("/");
  };

  const { id } = useParams();
  const [payDataDetail, setPayDataDetail] = useState(null);
  const [payImg, setPayImg] = useState([]);
  const [cardOpen, setCardOpen] = useState(false);
  const [user, setUser] = useState({});

  useEffect(() => {
    getProductInfoById(id);
    // eslint-disable-next-line
  }, [id]);

  const getProductInfoById = (id) => {
    getProductById(id)
      .then((response) => {
        // alert("fsdf");
        console.log("foundHotel gsf ", response?.data);
        const hotelData = response?.data;

        if (hotelData) {
          const images = hotelData.hotelBasePrice[0]?.images;
          setPayImg(images);
          console.log(hotels.hotel);
          console.log("foundHotel  fd", hotelData);
          const priceObject = hotelData.hotelBasePrice[0]?.base_price;
          const updatedHotelData = { ...hotelData, price: priceObject };
          console.log("updatedHotelData", updatedHotelData);
          setPayDataDetail(updatedHotelData);
        }
      })
      .catch((e) => {
        //console.log(e);
        // alert("hello");
      });
  };
  console.log("payDataDetail?.price", payDataDetail?.price);
  return (
    <div className="payMain">
      <BreadCrumb title={payDataDetail?.hotelName} />
      <div className="paymentB1 mb-4 mt-4">
        <div className="payB1B1">
          <div className="paymentB1Main">
            {cardOpen ? (
              <>
                <PaymentInpDetail
                  setCardOpen={setCardOpen}
                  payDataDetail={payDataDetail}
                  user={user}
                />
                <PaymentCompleteOpen
                  price={
                    payDataDetail.price -
                    Math.round(payDataDetail.price / 4) -
                    Math.round(payDataDetail.price / 20) +
                    399
                  }
                  setConfirm={setConfirm}
                />
              </>
            ) : (
              <>
                <PaymentDetail
                  payDataDetail={payDataDetail}
                  setCardOpen={setCardOpen}
                  setUser={setUser}
                />
                <PaymentCompleteClosed />
              </>
            )}
          </div>
          <PaymentSide
            initVar={initVar}
            price={payDataDetail?.price}
            payImg={payImg}
            payDataDetail={payDataDetail}
          />
        </div>
      </div>

      {confirm && (
        <div className="payBlur">
          <div>
            <div className="paymentConfirmed">
              <div className="payTick">
                <img src="/Images/Payment/Group.svg" alt="" />
              </div>
              <div>Booking Confirmed</div>
              <div>
                You will receive a mail in your above mentioned email-ID
              </div>
              <div>View Order Details</div>
              <button onClick={handleleave} className="payThankYou">
                THANK YOU
              </button>
            </div>
            <div>
              <PaymentSide
                initVar={initVar}
                payImg={payImg}
                payDataDetail={payDataDetail}
              />
            </div>
          </div>
        </div>
      )}
      <FooterTwo />
    </div>
  );
}
