import { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import "./PaymentStyle.css";
import moment from "moment";
import "moment/locale/en-au";
import { Box, CircularProgress, Grid, Stack } from "@mui/material";
import { DatePicker, Space } from "antd";
import { useDispatch, useSelector } from "react-redux";
import PriceSection from "../Pricesection";
const PayImgDiv = styled.div`
  width: 80px;
  height: 69px;
  border: 1px solid #858585;
  border-radius: 4px;
  background-size: contain;

  & img {
    width: 100%;
    height: 100%;
  }
`;

export function PaymentSide({ initVar, payDataDetail, payImg, price }) {
  return (
    <>
      <div className="paymentB1Side">
        <div className="hotel-info">
          <div>
            <div className="paymentSideHead">{payDataDetail?.hotelName}</div>
            <div className="paymentSideNew">NEW</div>
            <div className="paymentSideDays">{Number(price)}/ Nights</div>
          </div>
          <PayImgDiv>
            <img
              src={payImg?.find((item) => item?.is_featured === "1")?.image}
              alt=""
            />
          </PayImgDiv>
        </div>
        {payDataDetail?.hotelBasePrice[0]?.id ? (
          <PriceSection
            roomId={payDataDetail?.hotelBasePrice[0]?.id}
            finalPrice={price}
          />
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </div>
        )}
      </div>
    </>
  );
}
