import "./PaymentStyle.css";
import styled from "styled-components";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { useParams, useHistory } from "react-router-dom";
import axios from "axios";
import Counter from "./Counter";
import swal from "sweetalert";
import { useSelector } from "react-redux";
import { getUserBooking, getUserBookingOnline } from "../../baseApi/Api";
import moment from "moment";
import Switch from "@material-ui/core/Switch";
import { CircularProgress, FormControlLabel } from "@mui/material";
const PayInput = styled.input`
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  border: 1px solid #d3d3d3;
  box-sizing: border-box;
  border-radius: 2px;
  padding: 14px;
  width: 250px;
`;

export function PaymentDetail(props) {
  console.log("hss", props);
  const { isLoggedIn, user } = useSelector((state) => state.authSlice);
  const { startDate, endDate, rooms, guests } = useSelector(
    (state) => state.searchslice
  );
  const { payDataDetail } = props;
  const [verifyData, setVerifyData] = useState(false);
  const [verifyCode, setVerifyCode] = useState(false);
  const [sendCode, setSendCode] = useState(false);
  const [userData, setUserData] = useState({
    name: "",
    email: "",
    mobile: "",
    password: "",
  });
  const [isTimer, setIsTimer] = useState(false);
  const [isMember, setisMember] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    var data = { ...userData, [name]: value };
    setUserData(data);
  };

  const handleCardOped = async () => {
    // setUser(userData);

    if (
      userData.email === "" ||
      userData.mobile === "" ||
      userData.name === ""
    ) {
      return;
    }
    let data = {
      date_from: moment(startDate).format("YYYY-MM-DD"),
      date_to: moment(endDate).format("YYYY-MM-DD"),
      adults: guests,
      kids: 1,
      room_type_id: payDataDetail?.hotelBasePrice[0]?.id,
      hall_type_id: payDataDetail?.hotelBasePrice[0]?.id,
      hotel_id: payDataDetail?.hotel_id,
      firstname: userData?.name,
      lastname: "",
      email: userData?.email,
      mobile: userData?.mobile,
      password: userData?.password,
      booking_type: "room",
      no_of_rooms: rooms,
      is_guest: isMember ? 1 : 0,
    };
    setIsLoading(true);
    try {
      // setIsLoading(true);
      const response = await getUserBooking(data);
      const products = response.data;
      console.log(products);
      setIsLoading(false);
      let newdata = {
        price: Number(products?.bookingDetail?.totalamount),
        guestid: products?.guestId,
        bookingid: products.OrderId,
      };

      let resData = await getUserBookingOnline(newdata);
      setIsLoading(false);
      var options = {
        key: resData?.data?.key_id,
        amount: resData?.data?.Order.amount.toString(),
        currency: "INR",
        name: payDataDetail?.hotelName,
        order_id: resData.data?.Order.orderId,
        callback_url: "https://staykr.in/api/paymentStatus",
        handler: function (response) {
          swal(
            "Booking Confirmed",
            "You will receive an email in your above-mentioned email-ID",
            "success"
          ).then(() => {
            history.push("/");
          });
        },
        prefill: {
          name: userData.name,
          email: userData.email,
          contact: userData.mobile,
        },
        theme: {
          color: "#326fa8",
        },
      };

      const rzp1 = new window.Razorpay(options);
      rzp1.open();
      rzp1.on("open", function (e) {
        console.log("Razorpay payment window opened", e);
      });
      rzp1.on("error", function (error) {
        console.error("Razorpay error:", error);
        // Additional code or actions to handle the error
      });
    } catch (error) {
      // throw Error(error);
      setIsLoading(false);
    }
  };

  const handleResend = () => {
    setIsTimer(true);
  };

  const handleOnClickCashHotel = async () => {
    if (
      userData.email === "" ||
      userData.mobile === "" ||
      userData.name === ""
    ) {
      return;
    }
    // let data = {
    //   categories: "categories",
    //   hotelName: payDataDetail?.hotelName,
    //   hotelCouponCode: "req?.body?.code",
    //   hotelImages: payDataDetail?.hotelGallery[0],
    //   userInfo: {
    //     firstName: userData?.name,
    //     email: userData?.email,
    //     address: userData?.address,
    //     phoneNumber: userData?.mobile,
    //     checkinDate: startDate,
    //     checkoutDate: endDate,
    //     room: rooms,
    //     guest: guests,
    //     document: "",
    //     documentId: "",
    //     documentImage: "",
    //     image: "",
    //   },
    //   finalprice: "",
    //   basePrice: payDataDetail?.hotelBasePrice[0]?.base_price,
    //   paymentStatus: 0,
    //   status: 0,
    //   paymentmetod: "Cash at hotel",
    //   paymentType: "offline",
    //   patmentStatus: "Pending",
    //   ownername: payDataDetail?.hotelName,
    // };
    console.log(JSON.stringify(userData, null, 2));
    let data = {
      date_from: moment(startDate).format("YYYY-MM-DD"),
      date_to: moment(endDate).format("YYYY-MM-DD"),
      adults: guests,
      kids: 1,
      room_type_id: payDataDetail?.hotelBasePrice[0]?.id,
      hall_type_id: payDataDetail?.hotelBasePrice[0]?.id,
      hotel_id: payDataDetail?.hotel_id,
      firstname: userData?.name,
      lastname: "",
      email: userData?.email,
      mobile: userData?.mobile,
      password: userData?.password,
      booking_type: "room",
      no_of_rooms: rooms,
      is_guest: isMember ? 1 : 0,
    };
    console.log(JSON.stringify(data, null, 2));
    setIsLoading(true);
    try {
      const response = await getUserBooking(data);
      const products = response.data;
      setIsLoading(false);
      swal(
        "Booking Confirmed",
        "You will receive an email in your above-mentioned email-ID",
        "success"
      ).then(() => {
        history.push("/");
      });
      return products;
    } catch (error) {
      setIsLoading(false);
      // throw Error(error);
    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      setUserData({
        name: user?.name,
        email: user?.email,
        mobile: user?.mobile,
        password: user?.password,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  return (
    <div className=" container m-0">
      <div className="payDetailHead">
        <div className="payDetailHeadB1">1</div>
        <div className="payDetailHeadB2">Enter your details</div>
      </div>
      <div className="payFillInputB1">
        <div className="payFillInputB1Note word-wrap">
          We will use these details to share your booking information{" "}
        </div>
        <div className="payInpBoxes">
          <div className="paymarginSpecial">
            <div className="paylabel">Full Name</div>
            <PayInput
              style={
                verifyData
                  ? { backgroundColor: "#e8f0fe" }
                  : { backgroundColor: "white" }
              }
              onChange={handleInputChange}
              name="name"
              type="text"
              value={userData.name}
              placeholder="Enter first and last name"
            />
          </div>
          <div>
            <div className="paylabel">Email Address</div>
            <PayInput
              style={
                verifyData
                  ? { backgroundColor: "#e8f0fe" }
                  : { backgroundColor: "white" }
              }
              value={userData?.email}
              onChange={handleInputChange}
              name="email"
              type="text"
              placeholder="name@abc.com"
            />
          </div>
          <div className="paymarginSpecial">
            <div className="paylabel">Mobile Number</div>
            <PayInput
              style={
                verifyData
                  ? { backgroundColor: "#e8f0fe" }
                  : { backgroundColor: "white" }
              }
              onChange={handleInputChange}
              name="mobile"
              type="tel"
              value={userData?.mobile}
              placeholder="e.g. 1234567890"
            />
          </div>
          {!isLoggedIn && (
            <FormControlLabel
              control={
                <Switch
                  checked={isMember}
                  onChange={() => setisMember(!isMember)}
                  color="primary"
                  aria-label="As Staykr Member"
                />
              }
              label="Staykr Member"
            />
          )}

          {isMember && !isLoggedIn && (
            <div className="paymarginSpecial">
              <div className="paylabel">Password</div>
              <PayInput
                onChange={handleInputChange}
                name="password"
                type="password"
              />
            </div>
          )}

          <div></div>
          <div className="text-center m-aut w-100">
            <button
              onClick={handleOnClickCashHotel}
              className="payPasscode"
              style={{ backgroundColor: "#128037" }}
              disabled={isLoading}
            >
              Cash At Hotel {isLoading ? "...Loading" : ""}
            </button>
            &nbsp;
            <button
              onClick={handleCardOped}
              className="payPasscode"
              style={{ backgroundColor: "#128037" }}
              disabled={isLoading}
            >
              Pay Now{isLoading ? "...Loading" : ""}
            </button>
          </div>
        </div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
}
