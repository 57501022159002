import { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
    Box,
    Stepper,
    Step,
    StepLabel,
    Grid,
    FormHelperText,
    Button,
    Alert
} from '@mui/material';
import PersonalInfo from './PersonalInfo';
import AccountDetails from './AccountDetails';
import ReviewInfo from './ReviewInfo';
import { getAllDeatils, uploadHotelDeatilsFile } from '../../baseApi/Api';

const steps = [' Hotel Details', 'Owner Info', 'Review and Submit'];

const StepForms = ({ onCLose }) => {
    const [activeStep, setActiveStep] = useState(0);
    const [userdeatils, setUserDetails] = useState(null)

    const handleBack = () => {
        setActiveStep((prevStep) => prevStep - 1);
    };
    const handleSubmit = async (value) => {
        console.log("activeStep=", activeStep, " steps.length=", steps.length)

        if (activeStep === steps.length - 1) {
            try {
                console.log(value)
                const finalObject = {
                    hotel_name: value.hotelName,
                    hotel_phone: value.hotelPhone,
                    address: value.hotelLocation,
                    city_id: value.city,
                    country_id: 101,
                    hotel_logo: value.hotelPhotes,
                    email: value.email,
                    firstname: value.firstName,
                    lastname: value.lastName,
                    phone: value.phone,
                    id_type: value.document,
                    id_no: value.documentNumber,
                    id_upload: value.aadhar,
                    title: "Mr",
                    gender: "Male",
                    region_id: "",
                };
                console.log(JSON.stringify(finalObject, null, 2))
                setActiveStep((prevStep) => prevStep + 1);
                const { data } = await uploadHotelDeatilsFile(finalObject);
                setUserDetails({
                    email: "",
                    password: "",
                    username: ""
                })
                return data;
            } catch (error) {
                console.log("An error occurred while submitting the form:", error);
            }
        } else {
            setActiveStep((prevStep) => prevStep + 1);
        }
    };
    const formik = useFormik({
        initialValues: {
            hotelName: '',
            hotelPhone: '',
            hotalLocation: '',
            city: "",
            country: "",
            hotelPhotes: [],
            email: '',
            firstName: '',
            lastName: '',
            phone: '',
            document: 'Aadhar',
            documentNumber: "",
            aadhar: '',
        },
        validationSchema: Yup.object().shape({
            hotelName: Yup.string()
                .required('Hotel Name is required'),
            hotelPhone: Yup.string()
                .required("Phone  is required")
                .test('test-name', 'Enter Valid Phone',
                    function (value) {
                        // eslint-disable-next-line
                        const phoneRegex = /^(\+91-|\+91|0)?\d{10}$/; // Change this regex based on requirement
                        let isValidPhone = phoneRegex.test(value);
                        if (!isValidPhone) {
                            return false;
                        }
                        return true;
                    }),
            hotalLocation: Yup.string()
                .required('Hotal Location is required'),
            city: Yup.string()
                .required('Hotal City is required'),
            country: Yup.string(),
            hotelPhotes: Yup.string()
                .required('Images is required'),

        }),
        onSubmit: handleSubmit
    });

    const formContent = (step) => {
        switch (step) {
            case 0:
                return <AccountDetails formik={formik} />;
            case 1:
                return <PersonalInfo formik={formik} />;
            case 2:
                return <ReviewInfo formik={formik} />;
            case 3:
                return <Alert severity="success">
                    Registration successful! We will contact you as soon as possible.
                </Alert>;
            default:
                return <div>SuccessFully</div>
        }
    };

    return (
        <Box
            sx={{
                maxWidth: '600px',
                padding: 2
            }}
        >
            <Stepper
                activeStep={activeStep}
                orientation="horizontal"
            >
                {steps.map((label, index) => (
                    <Step key={index}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            <Grid container>
                <Grid
                    item
                    xs={12}
                    sx={{ padding: '20px' }}
                >
                    {formContent(activeStep)}
                </Grid>
                {formik.errors.submit && (
                    <Grid
                        item
                        xs={12}
                    >
                        <FormHelperText error>
                            {formik.errors.submit}
                        </FormHelperText>
                    </Grid>
                )}
                <Grid
                    item
                    xs={12}
                >
                    {activeStep === 3 ? (
                        <Button
                            onClick={onCLose}
                        >
                            Close
                        </Button>
                    ) : (
                        <>

                            {activeStep === steps.length - 1 ? (
                                <Button onClick={formik.handleSubmit}>
                                    Submit
                                </Button>
                            ) : (
                                <Button onClick={formik.handleSubmit}>
                                    Next
                                </Button>
                            )}
                        </>
                    )}

                </Grid>
            </Grid>
        </Box>
    )
}

export default StepForms;