import { Typography } from 'antd';
import React, { useRef, useEffect } from 'react';

const DescriptionsRef = ({ htmlContent }) => {
    const descriptionsRef = useRef(null);

    useEffect(() => {
        if (descriptionsRef.current) {
            descriptionsRef.current.innerHTML = htmlContent;
        }
    }, [htmlContent]);

    return <Typography ref={descriptionsRef} className="descriptions text-wrap"></Typography>;
};

export default DescriptionsRef;
