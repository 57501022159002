import React, { useEffect, useState } from 'react';
import { getPrice } from '../../baseApi/Api';
import moment from 'moment';
import { FaRupeeSign } from 'react-icons/fa';
import { Box } from '@mui/material';

function FinalPrice({ basePrice, guestCount, roomId, roomCount, startDate, endDate }) {
    const [price, setPrice] = useState(null);
    const [Taxprice, setTaxPrice] = useState(null);
    function calculateNights(startDate, endDate) {
        // Convert start and end dates to Date objects
        const start = new Date(startDate);
        const end = new Date(endDate);

        // Calculate the time difference in milliseconds
        const timeDiff = end.getTime() - start.getTime();

        // Check if the dates are the same day
        if (timeDiff < 1000 * 3600 * 24) {
            return 1; // Return 1 night if same day
        }

        // Convert milliseconds to days
        const nights = Math.floor(timeDiff / (1000 * 3600 * 24));

        return nights;
    }
    let date = moment()
    useEffect(() => {
        // Fetch the price from the API using the provided parameters
        const fetchPrice = async () => {
            try {
                // Make an API call to retrieve the price

                // Parse the response and update the price state
                let priceData = {
                    booking_type: "room",
                    date_from: moment(startDate || date).format("YYYY-MM-DD"),
                    date_to: moment(endDate || date).format("YYYY-MM-DD"),
                    room_type_id: roomId,
                    no_of_rooms: roomCount,
                    adults: guestCount,
                    kids: 1,
                    // base_price: basePrice
                }
                const { data } = await getPrice(priceData);
                setPrice(data?.total ?? 1200);

                setTaxPrice(data?.taxamount)
            } catch (error) {
                console.error('Error fetching price:', error);
            }
        };

        // Call the fetchPrice function
        fetchPrice();
    }, [basePrice, roomId, guestCount, roomCount, startDate, endDate]);

    if (price === null) {
        // Display a loading state while fetching the price
        return <div>Loading...</div>;
    }

    return <p>
        <Box
            sx={{ width: ["100%", "auto"] }}
            className="rooms roomdiv"

        >
            <p>
                {calculateNights(startDate, endDate)} Night,   {roomCount} Room, {guestCount} Guest
            </p>
        </Box>
        <h6 className='mb-3'>Base Price: ₹ {basePrice}</h6>
        <h6 className='mb-3'>Tax Amount: ₹ {Taxprice}</h6>
        <h6 className='mb-3'>Total Price: ₹ {price}</h6></p>;
}

export default FinalPrice;
