import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { Card, CardContent, CardMedia, Typography } from '@mui/material';
import Star from "../Star"
import { Row, Col } from 'react-bootstrap';
import { FaMapMarkerAlt, FaBed, FaExpandArrowsAlt, FaArrowRight, FaWifi } from 'react-icons/fa';
import styled from 'styled-components';
import { OfferBadge } from './Styled';
import { Link } from 'react-router-dom';

const StyledCardMedia = styled(CardMedia)`
  height: 200px;
  transition: transform 0.2s;
  &:hover {
    transform: scale(1.1);  
  }
`;
function HotelCard({ hotel }) {
    const { hotelGallery, name, rating, hotelName, beds, City, hotelBasePrice, hotel_id } = hotel;
    const { startDate, endDate, rooms, guests } = useSelector(
        (state) => state.searchslice
    );
    const isFetured = hotelBasePrice[0]?.images?.find(item => item?.is_featured === "1")
    console.log("isFetured", isFetured)
    const [finalPrice, setFinalPrice] = useState(
        Number(hotelBasePrice[0]?.base_price)
    );


    return (
        <Card className='m-1'>
            <Link
                onClick={() => {
                    window.scrollTo({
                        top: 0,
                        behavior: "smooth", // Optionally, use 'auto' or 'smooth' for smooth scrolling
                    });
                }} to={`/hoteldetail/${hotel_id}`} >
                <StyledCardMedia
                    component="img"
                    image={isFetured?.image}

                    alt={hotelGallery[0]?.title}
                /></Link>
            <CardContent>
                <Link onClick={() => {
                    window.scrollTo({
                        top: 0,
                        behavior: "smooth", // Optionally, use 'auto' or 'smooth' for smooth scrolling
                    });
                }} to={`/hoteldetail/${hotel_id}`} className="fs-small text-dark text-decoration-none" ><h5 as={"h6"} sx={{ fontFamily: "'Open Sans',sans-serif", margin: 1 }} className='text-truncate text-dark' title={hotelName} variant="h5" component="div" >{hotelName}</h5></Link>
                <Typography variant="body2" color="text.secondary">   <Star stars={rating || 4} /></Typography>
                <Row className='mt-2'>
                    <Col sm={3} xs={3}>
                        <Typography variant="body2" color="text.secondary"><FaWifi />&nbsp;10</Typography>
                    </Col>
                    <Col sm={3} xs={3}>
                        <Typography variant="body2" color="text.secondary"><FaBed></FaBed>&nbsp;2 </Typography>
                    </Col>
                    <Col sm={3} xs={3}>
                        <Typography variant="body2" color="text.secondary"><FaMapMarkerAlt />&nbsp;Delhi </Typography>
                    </Col>
                    <Col sm={3} xs={3}>
                        <Typography variant="body2" color="text.secondary"><FaExpandArrowsAlt />&nbsp; 600 </Typography>
                    </Col>
                </Row>
                <Row>
                    <Col xs={6}>
                        <Typography variant='h6' sx={{ mt: 1 }} >₹ {finalPrice}<small>/Night</small></Typography>
                    </Col>
                    <Col xs={6} className='p-3' onClick={() => {
                        window.scrollTo({
                            top: 0,
                            behavior: "smooth", // Optionally, use 'auto' or 'smooth' for smooth scrolling
                        });
                    }} >
                        <Link style={{ marginTop: "10px" }} className=' btn btn-sm btn-success  text-decoration-none' to={`/hoteldetail/${hotel_id}`} >Book Now <FaArrowRight /> </Link>
                    </Col>
                </Row>

            </CardContent>
        </Card >
    );
}

export default HotelCard;
