export const hotels = {
  hotel: [
    {
      id: 1,
      poster:
        "https://images.oyoroomscdn.com/uploads/hotel_image/107750/large/7df242e6af32ec64.jpg",
      images: [
        "https://images.oyoroomscdn.com/uploads/hotel_image/107750/large/7df242e6af32ec64.jpg",
        "https://images.oyoroomscdn.com/uploads/hotel_image/107750/large/bd8d99c4d6bddacd.jpg",
        "https://images.oyoroomscdn.com/uploads/hotel_image/107750/large/bd8d99c4d6bddacd.jpg",
      ],
      name: "SPOT ON 76794 Joshi Lodge",
      location: "PANAR ROAD, KATOL",
      city: "Nagpur",
      descripition:
        "Did you know that we’ve got 2.5 Crore bookings since March 2020? And this is all thanks to the sanitisation & safety measures followed at our properties, from disinfecting surfaces with high-quality cleaning products and maintaining social distance to using protective gear and more.",
      rating: 4.6,
      price: 516,
      rooms: 5,
      beds: 2,
      size: 750,
      amenities: ['Pool',]
    },
    {
      id: 2,
      poster:
        "https://images.oyoroomscdn.com/uploads/hotel_image/114925/large/682e0e11516478f9.jpg",
      images: [
        "https://images.oyoroomscdn.com/uploads/hotel_image/114925/large/682e0e11516478f9.jpg",
        "https://images.oyoroomscdn.com/uploads/hotel_image/114925/large/efb7fd1b7c796300.jpg",
        "https://images.oyoroomscdn.com/uploads/hotel_image/114925/large/fbe2eba7850811da.jpg",
        "https://images.oyoroomscdn.com/uploads/hotel_image/114925/large/a4afe78c79562eb7.jpg",
      ],
      name: "Staykr Flagship 82784 Hotel Trigon",
      location: "Nagpur District, Maharashtra, 440036, India, Nagpur",
      city: "Nagpur",
      descripition:
        "Did you know that we’ve got 2.5 Crore bookings since March 2020? And this is all thanks to the sanitisation & safety measures followed at our properties, from disinfecting surfaces with high-quality cleaning products and maintaining social distance to using protective gear and more.",
      rating: 4.2,
      price: 1274,
      rooms: 10,
      beds: 2,
      size: 550,
      amenities: ['Gym',]

    },
    {
      id: 3,
      poster:
        "https://images.oyoroomscdn.com/uploads/hotel_image/95621/large/5cbc33b4f76ab598.jpg",
      images: [
        "https://images.oyoroomscdn.com/uploads/hotel_image/95621/large/5cbc33b4f76ab598.jpg",
        "https://images.oyoroomscdn.com/uploads/hotel_image/95621/large/35a9be751618bfc8.jpg",
        "https://images.oyoroomscdn.com/uploads/hotel_image/95621/large/c7aa58d2279e5a8d.jpg",
        "https://images.oyoroomscdn.com/uploads/hotel_image/95621/large/7b13d724ba5b085c.jpg",
      ],
      name: "Staykr 69614 Hotel Diamond",
      location: "Near BKC palace inn, MMRDA Area, Mumbai",
      city: "Mumbai",
      descripition:
        "Did you know that we’ve got 2.5 Crore bookings since March 2020? And this is all thanks to the sanitisation & safety measures followed at our properties, from disinfecting surfaces with high-quality cleaning products and maintaining social distance to using protective gear and more.",
      rating: 4.3,
      price: 1621,
      rooms: 20,
      beds: 2,
      size: 650,
      amenities: ['Spa',]

    },
    {
      id: 4,
      images: [
        "https://images.oyoroomscdn.com/uploads/hotel_image/97864/large/72bfea3cc34e2fa2.jpg",
        "https://images.oyoroomscdn.com/uploads/hotel_image/97864/large/b2c30e3ae73e72e2.jpg",
        "https://images.oyoroomscdn.com/uploads/hotel_image/97864/large/be1e86edf636fe4f.jpg",
        "https://images.oyoroomscdn.com/uploads/hotel_image/97864/large/61353c778fb1dcd0.jpg",
      ],
      name: "Staykr 70661 Hotel Al Makkah",
      location: "Near Minara Masjid, Mohd Ali Road, Mumbai",
      city: "Mumbai",
      descripition:
        "Did you know that we’ve got 2.5 Crore bookings since March 2020? And this is all thanks to the sanitisation & safety measures followed at our properties, from disinfecting surfaces with high-quality cleaning products and maintaining social distance to using protective gear and more.",
      rating: 3.7,
      price: 1275,
      rooms: 30,
      beds: 1,
      size: 650,
      amenities: ['Gym', 'Spa', 'Restaurant', "Seating area", "TV", "Room Heater", "Mini Fridge"]

    },
    {
      id: 5,
      images: [
        "https://images.oyoroomscdn.com/uploads/hotel_image/100/large/b144be30207a9984.jpg",
        "https://images.oyoroomscdn.com/uploads/hotel_image/100/large/9dd2733692cd2df8.jpg",
        "https://images.oyoroomscdn.com/uploads/hotel_image/100/large/c8a650b9d1d99404.jpg",
        "https://images.oyoroomscdn.com/uploads/hotel_image/100/large/f247c634ff9fb876.jpg",
      ],
      name: "Capital O 342 Royal Residency",
      location: "Near DLF Phase 3, T Block, Gurgaon",
      city: "Delhi",
      descripition:
        "Did you know that we’ve got 2.5 Crore bookings since March 2020? And this is all thanks to the sanitisation & safety measures followed at our properties, from disinfecting surfaces with high-quality cleaning products and maintaining social distance to using protective gear and more",
      rating: 3.9,
      price: 2482,
      rooms: 20,
      beds: 2,
      size: 650,
      amenities: ['Pool', 'Gym', 'Spa', 'Restaurant', "Seating area", "TV", "Room Heater", "Mini Fridge"]

    },
    {
      id: 6,
      images: [
        "https://images.oyoroomscdn.com/uploads/hotel_image/349/large/1b843d037a8b8a63.jpg",
        "https://images.oyoroomscdn.com/uploads/hotel_image/349/large/afdb41053cb3c8ac.jpg",
        "https://images.oyoroomscdn.com/uploads/hotel_image/349/large/8d1bef040936abb9.jpg",
        "https://images.oyoroomscdn.com/uploads/hotel_image/349/large/3926ffe6b368481f.jpg",
      ],
      name: "Staykr 497 Hotel Welcome Palace",
      location: "Near New Delhi Railway Station, Delhi",
      city: "Delhi",
      descripition:
        "Did you know that we’ve got 2.5 Crore bookings since March 2020? And this is all thanks to the sanitisation & safety measures followed at our properties, from disinfecting surfaces with high-quality cleaning products and maintaining social distance to using protective gear and more.",
      rating: 3.8,
      price: 1825,
      rooms: 20,
      beds: 2,
      size: 650,
      amenities: ['Pool', 'Gym', 'Spa', 'Restaurant', "Seating area", "TV", "Room Heater", "Mini Fridge"]

    },
    {
      id: 7,
      images: [
        "https://images.oyoroomscdn.com/uploads/hotel_image/391/large/fd306a968a5dc2ce.jpg",
        "https://images.oyoroomscdn.com/uploads/hotel_image/391/large/e451c3fff22fd200.jpg",
        "https://images.oyoroomscdn.com/uploads/hotel_image/391/large/de022eec7167195d.jpg",
        "https://images.oyoroomscdn.com/uploads/hotel_image/391/large/bd0d7c5c14813dc8.jpg",
      ],
      name: "Staykr 526 Hotel Asees Inn",
      location: "Near Kirti Nagar, Kirtinagar, Delhi",
      city: "Delhi",
      descripition:
        "Located in Delhi, Hotel Asees Inn is a budget hotel with a standardised set of amenities at the best value. The hotel caters to a wide variety of customers from students, tourists to professional travellers.",
      rating: 3.4,
      price: 2590,
      rooms: 20,
      beds: 2,
      size: 650,
      amenities: ['Pool', 'Gym', 'Spa', 'Restaurant', "Seating area", "TV", "Room Heater", "Mini Fridge"]

    },
    {
      id: 8,
      images: [
        "https://images.oyoroomscdn.com/uploads/hotel_image/315/large/0f9b06237bfb735f.jpg",
        "https://images.oyoroomscdn.com/uploads/hotel_image/315/large/a3a3056f26722e68.jpg",
        "https://images.oyoroomscdn.com/uploads/hotel_image/315/large/cf9e6eb35daeb5f1.jpg",
        "https://images.oyoroomscdn.com/uploads/hotel_image/315/large/01c93a9e14de9ae9.jpg",
      ],
      name: "Staykr 476 Hotel Gera's",
      location: "Plot no. G-66, Opposite Karnataka Bank, Kalkaji, Delhi",
      city: "Delhi",
      descripition:
        "Did you know that we’ve got 2.5 Crore bookings since March 2020? And this is all thanks to the sanitisation & safety measures followed at our properties, from disinfecting surfaces with high-quality cleaning products and maintaining social distance to using protective gear and more.",
      rating: 4.3,
      price: 1678,
      rooms: 20,
      beds: 2,
      size: 650,
      amenities: ['Pool', 'Gym', 'Spa', 'Restaurant', "Seating area", "TV", "Room Heater", "Mini Fridge"]

    },
    {
      id: 9,
      images: [
        "https://images.oyoroomscdn.com/uploads/hotel_image/50593/large/bd3c4983669b520c.jpg",
        "https://images.oyoroomscdn.com/uploads/hotel_image/50593/large/a6172096763ca03e.jpg",
        "https://images.oyoroomscdn.com/uploads/hotel_image/50593/large/3c6ee6a8931c3f4a.jpg",
        "https://images.oyoroomscdn.com/uploads/hotel_image/50593/large/78398103d8b8fa8f.jpg",
      ],
      name: "Capital O 29584 Fortune Gateway",
      location: "Kannaiah Layout Mvit College Road, Bangalore",
      city: "Bangalore",
      descripition:
        "Did you know that we’ve got 2.5 Crore bookings since March 2020? And this is all thanks to the sanitisation & safety measures followed at our properties, from disinfecting surfaces with high-quality cleaning products and maintaining social distance to using protective gear and more.",
      rating: 4.7,
      price: 1300,
      rooms: 20,
      beds: 2,
      size: 650,
      amenities: ['Pool', 'Gym', 'Spa', 'Restaurant', "Seating area", "TV", "Room Heater", "Mini Fridge"]

    },
    {
      id: 10,
      images: [
        "https://images.oyoroomscdn.com/uploads/hotel_image/35587/large/326f8058f3dfbb14.jpg",
        "https://images.oyoroomscdn.com/uploads/hotel_image/35587/large/576c3219da0de5ea.jpg",
        "https://images.oyoroomscdn.com/uploads/hotel_image/35587/large/555d9369761f3f73.jpg",
        "https://images.oyoroomscdn.com/uploads/hotel_image/35587/large/e96145347f3d95b4.jpg",
      ],
      name: "Staykr 13567 Sri Sai Guru Comforts",
      location: "Near Kalyan Nagar, Babu Sahibpalya, Bangalore",
      city: "Bangalore",
      descripition:
        "Location Suites and Rooms is known for their round the clock services and is situated near HRBR Layout. Special Features Guests will be captivated by the stylish and sensual ambience of this hotel.",
      rating: 4.5,
      price: 1653,
      rooms: 20,
      beds: 2,
      size: 650,
      amenities: ['Pool', 'Gym', 'Spa', 'Restaurant', "Seating area", "TV", "Room Heater", "Mini Fridge"]

    },
    {
      id: 11,
      images: [
        "https://images.oyoroomscdn.com/uploads/hotel_image/94528/large/c460603ea892ce0f.jpg",
        "https://images.oyoroomscdn.com/uploads/hotel_image/94528/large/986a321f2a1e7ddf.jpg",
        "https://images.oyoroomscdn.com/uploads/hotel_image/94528/large/7497579510b50801.jpg",
        "https://images.oyoroomscdn.com/uploads/hotel_image/94528/large/46b4eefdc92344ec.jpg",
      ],
      name: "Capital O 68975 The Blueberry's",
      location: "Near Forum Mall, 4th Cross Rd, Bangalore",
      city: "Bangalore",
      descripition:
        "Did you know that we’ve got 2.5 Crore bookings since March 2020? And this is all thanks to the sanitisation & safety measures followed at our properties, from disinfecting surfaces with high-quality cleaning products and maintaining social distance to using protective gear and more.",
      rating: 4.2,
      price: 1435,
      rooms: 20,
      beds: 2,
      size: 650,
      amenities: ['Pool', 'Gym', 'Spa', 'Restaurant', "Seating area", "TV", "Room Heater", "Mini Fridge"]

    },
    {
      id: 12,
      images: [
        "https://images.oyoroomscdn.com/uploads/hotel_image/106791/large/eb6a50ef7ceec20d.jpg",
        "https://images.oyoroomscdn.com/uploads/hotel_image/106791/large/eca4f8a9746e893d.jpg",
        "https://images.oyoroomscdn.com/uploads/hotel_image/106791/large/5eddc396ba0dcf6e.jpg",
        "https://images.oyoroomscdn.com/uploads/hotel_image/106791/large/27f7365411e86597.jpg",
      ],
      name: "Staykr TOWNHOUSE 332 Varthur Main Road",
      location: "15, Thubarahalli, Whitefield, Bangalore",
      city: "Bangalore",
      descripition:
        "Did you know that we’ve got 2.5 Crore bookings since March 2020? And this is all thanks to the sanitisation & safety measures followed at our properties, from disinfecting surfaces with high-quality cleaning products and maintaining social distance to using protective gear and more.",
      rating: 4.5,
      price: 1590,
      rooms: 20,
      beds: 2,
      size: 650
    },
    {
      id: 13,
      images: [
        "https://images.oyoroomscdn.com/uploads/hotel_image/105229/large/1487613012a5ea11.JPG",
        "https://images.oyoroomscdn.com/uploads/hotel_image/105229/large/9f22317d75ac7cbf.JPG",
        "https://images.oyoroomscdn.com/uploads/hotel_image/105229/large/3d3e902baf131fb9.JPG",
        "https://images.oyoroomscdn.com/uploads/hotel_image/105229/large/456d40832cdd267d.JPG",
      ],
      name: "Collection O 18927 Maya Palace Mayapuri",
      location: "Near Mayapuri Industrial Area Phase II, Delhi",
      city: "Delhi",
      descripition:
        "Collection O 18927 Maya Palace Mayapuri is a luxury hotel in Delhi that offers state-of-the-art amenities and is located close to India Gate and Red Fort. The hotel also offers breakfast, kitchen, and CCTV cameras.",
      rating: 4.1,
      price: 1548,
      rooms: 20,
      beds: 2,
      size: 650,
      amenities: ['Pool', 'Gym', 'Spa', 'Restaurant', "Seating area", "TV", "Room Heater", "Mini Fridge"]

    },
    {
      id: 14,
      images: [
        "https://images.oyoroomscdn.com/uploads/hotel_image/15977/large/deccd6d049538389.jpg",
        "https://images.oyoroomscdn.com/uploads/hotel_image/15977/large/7d0b2f661a96d1c1.jpg",
        "https://images.oyoroomscdn.com/uploads/hotel_image/15977/large/c10471c8af7f0b1a.jpg",
        "https://images.oyoroomscdn.com/uploads/hotel_image/15977/large/43e8a6e857c8d4a3.jpg",
      ],
      name: "Staykr 9726 Hotel Aura Palace Deluxe",
      location: "Near Near Sai Mandir , Pahar Ganj, Delhi",
      city: "Delhi",
      descripition:
        "Did you know that we’ve got 2.5 Crore bookings since March 2020? And this is all thanks to the sanitisation & safety measures followed at our properties, from disinfecting surfaces with high-quality cleaning products and maintaining social distance to using protective gear and more",
      rating: 4.8,
      price: 1029,
      rooms: 20,
      beds: 2,
      size: 650,
      amenities: ['Pool', 'Gym', 'Spa', 'Restaurant', "Seating area", "TV", "Room Heater", "Mini Fridge"]

    },
  ],
};
