import { RouteMain } from "./Routes/RouteMain";
import 'bootstrap/dist/css/bootstrap.css';
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import "./index.css";
import { initializeProducts } from "./store/slice/productSlice";

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    // dispatch(initializeProducts()); 

    // Set browser zoom level to 80% when the page loads
    document.body.style.zoom = "95%";
  }, [dispatch]);

  return (
    <div>
      <RouteMain />
    </div>
  );
}

export default App;
