import React, { useState, useEffect } from 'react'
import { hotels } from "../db";
import { HotelInfo } from '../HotelInfo/HotelInfo';
import { v4 as uuidv4 } from 'uuid';
import { useSelector } from 'react-redux';

const TrandingHotal = (props) => {
    const [cardData, setCardData] = useState([]);
    const { products } = useSelector(
        (state) => state.productSlice
    );
    useEffect(() => {
        const data = hotels;
        console.log("props", props);
        if (props?.city) {
            let dataold = data.hotel.filter((item) => item?.city === props?.city?.city);
            setCardData(dataold);
            console.log("datassold", dataold);
        } else {
            setCardData(data.hotel);
        }
    }, []);
    return (
        <div className='container p-2'>
            {products.slice(-5).map((e) => {
                return <HotelInfo key={uuidv4()} data={e} />;
            })}
        </div>
    )
}

export default TrandingHotal