import React, { useState } from "react";
import "./Slider.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Stack } from "@mui/material";
import { Card } from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";
import ReactImageZoom from "react-image-zoom";
export function SliderMain({ arr, setImageOpen }) {
  const [img, setImg] = useState(0);
  console.log(img);
  const [slider, setSlider] = useState(null);
  const settings = {
    dots: false,
    arrows: true,
    fade: false,
    infinite: true,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: (currentSlide) => {
      setImg(currentSlide);
    },
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handleImg = (index) => {
    // console.log(e.target.src);
    slider?.slickGoTo(index);
  };
  // console.log(arr[0]);
  return (
    <>
      <Slider
        {...settings}
        className="h-100 w-100"
        style={{ maxHeight: "500px", height: "500px" }}
        ref={(slider) => setSlider(slider)}
      >
        {arr.map((item, index) => {
          return (
            <Card className="m-2 rounded">
              {" "}
              <Card.Img
                src={item?.image}
                alt="no"
                style={{
                  cursor: "pointer",
                  maxHeight: "450px",
                  aspectRatio: "16/16",
                }}
                className="rounded h-100"
              />{" "}
            </Card>
          );
        })}
      </Slider>

      <Stack
        direction={"row"}
        className="m-2 col-sm-12 justify-content-center align-items-center"
      >
        {arr?.map((item, index) => (
          <Card className="m-2 rounded">
            <Card.Img
              key={uuidv4()}
              src={item?.image}
              style={{
                cursor: "pointer",
                maxHeight: "100px",
                // aspectRatio: "1/1",
              }}
              onClick={() => setImageOpen(item?.image)}
            />
          </Card>
        ))}
      </Stack>
    </>
  );
}
