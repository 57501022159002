import React, { useState } from 'react'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Card, Row, Col, Button } from "react-bootstrap";
import { CardMedia } from "@material-ui/core";
import { BiLeftArrowAlt, BiRightArrowAlt } from "react-icons/bi";
import { Typography } from 'antd';
import { TopLocationBg } from './Styles';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
const TopLocation = (props) => {
    const [slider, setSlider] = useState(null);
    const { city, cityList, startDate, endDate, rooms, guests } = useSelector(
        (state) => state.searchslice
    );
    const { products } = useSelector(
        (state) => state.productSlice
    );
    const settings = {
        dots: false,
        arrows: false,
        fade: false,
        infinite: true,
        autoplay: true,
        speed: 1000,
        autoplaySpeed: 2000,
        pauseOnHover: true,
        slidesToShow: 4,
        slidesToScroll: 4,

        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        // <div>
        //     <div>
        //         <Card >
        //             <div style={{ position: "relative" }}>
        //                 <CardMedia style={{ height: "250px", paddingTop: "2%" }} component="img" image={"https://miro.medium.com/v2/resize:fit:720/format:webp/1*qXdHwr9QbgrD0MFEsV-SuQ.png"} title="Pancakes" alt="Pancakes" />
        //                 <div style={{ position: "absolute", color: "white", top: 10, left: "50%", transform: "translateX(-50%)", }}> Some text</div>
        //             </div>
        //         </Card>
        //     </div>
        // </div>
        <TopLocationBg className='container-fluid  '>



            <Row className='pb-5 pt-5 text-center'>
                <Col lg={3} className="d-flex justify-content-center align-items-center flex-column" >
                    <h2 className='text-left text-danger mb-1'>Top Location</h2>
                    <Typography as={"h4"} sx={{ fontWeight: 800 }} className='fw-bolder'> Discover best location for your next trip</Typography>
                    <Link to="/hotel-locations" className="w-50">
                        <Button variant='success mt-2 w-100 ' >View</Button>
                    </Link>
                </Col>
                <Col lg={8} sm={10} xs={10}>
                    <div className="position-relative">
                        <button
                            type="button"
                            className="btn btn-light btn-badge position-absolute"
                            style={{
                                left: "2%",
                                top: "50%",
                                // transform: "translate(-100%, -50%)",
                                zIndex: 2,
                            }}
                            onClick={() => slider?.slickPrev()}
                        >
                            <BiLeftArrowAlt />
                        </button>
                        <button
                            type="button"
                            className="btn btn-light btn-badge position-absolute"
                            style={{
                                right: "1%",
                                top: "50%",
                                // transform: "translate(50%, -50%)",
                                zIndex: 2,
                            }}
                            onClick={() => slider?.slickNext()}
                        >
                            <BiRightArrowAlt />
                        </button>
                        <Slider {...settings} className='h-100 w-100' ref={(slider) => setSlider(slider)}>
                            {products?.map((item, index) => {
                                return (
                                    <div className='p-0 rounded-2 bg-none  m-1' style={{ width: "100%", backgroundColor: "none", borderRadius: "15px", }} >
                                        <div style={{ position: "relative", borderRadius: "15px" }} className='p-0 m-2'>
                                            <CardMedia style={{ height: "60vh", paddingTop: "2%", borderRadius: "12px" }} component="img" image={item?.hotelBasePrice[0]?.images?.find(item => item?.is_featured === "1")?.image} title="Pancakes" alt="Pancakes" />
                                            <Link onClick={() => {
                                                window.scrollTo({
                                                    top: 0,
                                                    behavior: "smooth", // Optionally, use 'auto' or 'smooth' for smooth scrolling
                                                });
                                            }}
                                                to={{
                                                    pathname: '/hotels',
                                                    search: `?location=${item?.area[0]?.id}`
                                                }}>
                                                <div className='h-100 w-100' style={{
                                                    position: "absolute",
                                                    background: "rgba(0, 32, 70, 0.50)",
                                                    top: "0px",
                                                    borderRadius: "12px",
                                                    display: "flex",
                                                    justifyContent: "flex-end",
                                                    alignItems: "center",
                                                    flexDirection: "column",
                                                    color: "#ffffff"
                                                }}>
                                                    <h4>
                                                        {item?.area[0]?.area || ""}
                                                    </h4>
                                                    <div className='d-flex'>
                                                        <h6>Average Price</h6> &nbsp;
                                                        <div><h6>₹{item?.hotelBasePrice[0]?.base_price ?? "1200"}</h6></div>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                );
                            })}
                        </Slider>  </div>
                </Col>
            </Row>
        </TopLocationBg >
    )
}

export default TopLocation