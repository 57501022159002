import React, { useState } from 'react'

import BreadCrumb from '../BreadCrumb'
import { useDispatch, useSelector } from 'react-redux';
import { FooterTwo } from '../FooterTwo'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Button, TextField, Alert, CircularProgress, OutlinedInput, IconButton, InputAdornment } from '@mui/material';
import Logo from "../../assets/image/Union.png";
import { loginUserAsync } from '../../store/slice/authSlice';
import { Redirect, Link } from 'react-router-dom';
import { FaEyeSlash, FaEye } from "react-icons/fa";
const Login = () => {
    const dispatch = useDispatch()
    const { isLoggedIn, isLoading, error } = useSelector(
        (state) => state.authSlice
    );
    const initialValues = {
        email: '',
        password: '',
    };
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .required("Email address is required")
            .test('test-name', 'Enter Valid Email/Phone',
                function (value) {
                    // eslint-disable-next-line
                    const emailRegex = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
                    let isValidEmail = emailRegex.test(value);
                    if (!isValidEmail) {
                        return false;
                    }
                    return true;
                }),
        password: Yup.string()
            .required("Password is required")
            .min(8, "Password must be at least 8 characters")
            .max(40, "Password must not exceed 40 characters"),
    });

    const onSubmit = (values) => {
        console.log(values);
        let data = {
            email: values.email,
            password: values.password,
        }
        dispatch(loginUserAsync(data))
        // Perform login logic here
    };

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit,
    });
    if (isLoggedIn) {
        return <Redirect to="/" />;
    };
    return (
        <>
            <BreadCrumb title="login" />
            <div className="login-area pt-120 pb-100">
                <div className="container p-3">
                    <div className="col-md-5 mx-auto">
                        <div className="login-form">
                            <div className="login-header">
                                <img
                                    src={Logo}
                                    alt=""
                                />

                                <p>Login with your account</p>
                            </div>
                            {error && (
                                <Alert variant="filled" severity="error">
                                    {error}
                                </Alert>
                            )}

                            <form onSubmit={formik.handleSubmit}>
                                <div className="form-group">
                                    <TextField
                                        id="email"
                                        name="email"
                                        label="Email"
                                        autoComplete={false}
                                        type="email"
                                        fullWidth
                                        value={formik.values.email}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.email && Boolean(formik.errors.email)}
                                        helperText={formik.touched.email && formik.errors.email}
                                        margin="normal"
                                    />

                                </div>
                                <div className="form-group">
                                    <OutlinedInput
                                        id="password"
                                        name="password"
                                        label="Password"
                                        placeholder='Password'
                                        type={showPassword ? 'text' : 'password'}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    edge="end"
                                                    size='small'
                                                >
                                                    {showPassword ? <FaEye /> : <FaEyeSlash />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        fullWidth
                                        autoComplete={false}
                                        value={formik.values.password}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.password && Boolean(formik.errors.password)}
                                        helperText={formik.touched.password && formik.errors.password}
                                        margin="normal"
                                    />
                                </div>
                                <div className="d-flex justify-content-between mb-4">
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            value=""
                                            id="remember"
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor="remember"
                                        >
                                            Remember Me
                                        </label>
                                    </div>
                                    <Link
                                        to="/forgot-password"
                                    >
                                        Forgot Password ?
                                    </Link>
                                </div>

                                <Button disabled={isLoading} type="submit" variant="contained" color="primary">
                                    Login {isLoading && (<CircularProgress size={20} />)}
                                </Button>
                            </form>
                            <div className="login-footer">
                                <p>
                                    Don't have an account? {" "}
                                    <Link to="/registration">
                                        Register
                                    </Link>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FooterTwo />
        </>
    )
}

export default Login