import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';

import { FaPlay } from 'react-icons/fa';
import { BiLeftArrowAlt, BiRightArrowAlt } from "react-icons/bi";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
// Play icon overlay effect animation
import { Modal, Button } from 'react-bootstrap';
const pulseAnimation = keyframes`
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
  }
  
  70% {
    transform: scale(1.2);
    box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
  }
  
  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
`;

// Styled components
const FullScreenSliderWrapper = styled.div`
  position: relative;

  .slick-slider {
    position: relative;
    height: 100vh;
    overflow: hidden;
  }

  .slick-slide > div {
    height: 100%;
  }

  .image-slide {
    position: relative;
    height: 100%;
  }

  .image-slide img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .image-slide::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: -webkit-linear-gradient( to top, rgba(0, 32, 70, 0.85), rgba(0, 32, 70, 0.1) );
    background: linear-gradient( to top,rgb(0 32 70 / 0%),rgba(0,32,70,0.1) );

    mix-blend-mode: multiply; /* Apply the desired blend mode */
  }

  .play-icon {
    position: absolute;
    top: 30%;
    left: 45%;
    transform: translate(-50%, -50%);
    width: 70px;
    height: 70px;
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    animation: ${pulseAnimation} 2s infinite;
  }

  .play-icon i {
    color: #ffffff;
    font-size: 36px;
  }

  .play-icon:hover {
    background-color: rgba(0, 0, 0, 0.8);
  }

  @media (max-width: 768px) {
    max-height: 220px;
    .play-icon {
      top: 39% !important;
      /* transform: translate(50%, -50%);
       */
      left: 43% !important;
      width: 40px;
    height: 40px;
    }
    .slick-slider {
    position: relative;
    height: 40vh;
    overflow: hidden;
  }
  }
`;


const HomeSliderEffect = () => {
  const [slider, setSlider] = useState(null);
  const [playVideo, setPlayVideo] = useState(null);
  const images = [
    {
      img: "Images/3.webp",
      video: "images/hotel.mp4"
    },
    {
      img: "Images/4.webp",
      video: "images/hotel.mp4"
    },
    {
      img: "Images/5.webp",
      video: "images/hotel.mp4"
    }

    // Add more image paths
  ];

  const VideoContainer = () => {
    return (
      // <AspectRatio ratio={1}>
      <iframe width="600" height="315" src="https://www.youtube.com/embed/l-0fUe0CSCw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      // </AspectRatio>
    )
  }

  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 10000,
    pauseOnHover: true,

  };

  return (
    <FullScreenSliderWrapper className="position-relative">

      <button
        type="button"
        className="btn btn-light btn-badge btn-sm rounded-circle position-absolute"
        style={{
          left: "2%",
          top: "50%",
          // transform: "translate(-100%, -50%)",
          zIndex: 1,
        }}
        onClick={() => slider?.slickPrev()}
      >
        <BiLeftArrowAlt />
      </button>
      <button
        type="button"
        className="btn rounded-circle btn-sm btn-light btn-badge position-absolute"
        style={{
          right: "1%",
          top: "50%",
          // transform: "translate(50%, -50%)",
          zIndex: 1,
        }}
        onClick={() => slider?.slickNext()}
      >
        <BiRightArrowAlt />
      </button>
      <Slider {...settings} ref={(slider) => setSlider(slider)}>
        {images.map((item, index) => (
          <div key={index} className="image-slide">
            <img loading="lazy" src={item.img} alt={`s ${index + 1}`} />
          </div>
        ))}
      </Slider>

      {playVideo && (
        <Modal dialogClassName="modal-60w" backdrop="static" animation={false} centered show={true} onHide={() => setPlayVideo(null)}>
          <Modal.Header className="m-0 p-0 text-right" style={
            {
              justifyContent: "end",
              display: "flex",
              alignItems: "center"
            }
          }>
            <Button className="ml-auto btn-rounded" size="sm" variant="light" onClick={() => setPlayVideo(null)}>&#10006;</Button>
          </Modal.Header>
          <Modal.Body>
            <VideoContainer />
          </Modal.Body>

        </Modal>

      )}
    </FullScreenSliderWrapper>
  );
};

export default HomeSliderEffect;
