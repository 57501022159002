import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { hotels } from "../../Components/db";
import { getAllProduct, getHotelListById } from "../../baseApi/Api";
import { useEffect } from "react";



export const fetchProducts = createAsyncThunk(
    "product/fetchProducts",
    async () => {
        try {
            const response = await getAllProduct(); // Replace with your actual API endpoint to fetch products
            const products = response.data;
            return products;
        } catch (error) {
            throw Error("Failed to fetch products");
        }
    }
);

export const fetchProductsByArea = createAsyncThunk(
    "product/getHotelListById",
    async (city) => {
        try {
            const response = await getHotelListById(city); // Replace with your actual API endpoint to fetch products
            const products = response.data;
            return products;
        } catch (error) {
            throw Error("Failed to fetch products");
        }
    }
);
const initialState = {
    view: "List",
    price: "lowest",
    product: [],
    filterProduct: [],
    priceRange: [0, 0],
    amenities: [],
};

const filterSliceData = createSlice({
    name: "filterSlice",
    initialState,
    reducers: {
        setView: (state, action) => {
            state.view = action.payload;
        },
        setPrice: (state, action) => {
            state.price = action.payload;
        },
        setProduct: (state, action) => {
            state.price = action.product;
        },
        setPriceShorting: (state, action) => {
            let newShortData;
            let tempShortData = [...state.filterProduct];

            if (action.payload === "lowest") {
                newShortData = tempShortData.sort((a, b) => a.price - b.price);
            }
            if (action.payload === "highest") {
                newShortData = tempShortData.sort((a, b) => b.price - a.price);
            }
            if (action.payload === "a-z") {
                newShortData = tempShortData.sort((a, b) => a.name.localeCompare(b.name));
            }
            if (action.payload === "z-a") {
                newShortData = tempShortData.sort((a, b) => b.name.localeCompare(a.name));
            }
            // console.log(newShortData)
            state.filterProduct = newShortData;
        },
        setLocationFilter: (state, action) => {
            const filterText = action.payload;
            const tempShortData = [...state.product];
            console.log("filterText", filterText);
            const newShortData = tempShortData.filter((item) => item?.hotelName.toLowerCase().includes(filterText.toLowerCase()));

            // console.log("dat", newShortData);


            state.filterProduct = filterText.trim() !== "" ? newShortData : state.product;
        },
        filterProductsPrice: (state, action) => {
            const range = action.payload;
            state.filterProduct = state.product.filter(
                (product) => product?.hotelBasePrice[0]?.base_price >= range[0] && product?.hotelBasePrice[0]?.base_price <= range[1]
            );
        },
        filterProductsRating: (state, action) => {
            const ratingRange = action.payload;
            state.ratingRange = ratingRange;
            // state.filterProduct = state.product.filter(
            //     (product) => product.rating >= ratingRange[0] && product.rating <= ratingRange[1]
            // );
        },
        setSelectedAmenitiesUpdate: (state, action) => {
            const amenitiesToFilter = ['Gym', 'Spa', 'Seating area'];
            const filteredItems = state.product.filter((item) =>
                amenitiesToFilter.every((amenity) =>
                    item.srzzehotelAnimities && item.srzzehotelAnimities.includes(amenity)
                )
            );

            console.log("filteredItems", filteredItems);

            state.filteredItems = filteredItems;
        },


        seLocationFilterData: (state, action) => {


            state.filterProduct = action.payload === "all" ? state.product : state.product.filter((item) => item?.City === action.payload);
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchProducts.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(fetchProducts.fulfilled, (state, action) => {
                state.isLoading = false;
                state.error = null;
                state.product = action.payload;
                state.filterProduct = action.payload;
            })
            .addCase(fetchProducts.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message;
            })
            .addCase(fetchProductsByArea.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(fetchProductsByArea.fulfilled, (state, action) => {
                state.isLoading = false;
                state.error = null;
                state.filterProduct = action.payload;
            })
            .addCase(fetchProductsByArea.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message;
            });
    },
});

export const { setView, setPrice, seLocationFilterData, setPriceShorting, setLocationFilter, filterProductsPrice, filterProductsRating, setSelectedAmenitiesUpdate } = filterSliceData.actions;
export const initializeFilterProducts = () => (dispatch) => {
    dispatch(fetchProducts());
    // Any other initialization logic you want to perform
};

export default filterSliceData.reducer;
