import styled from 'styled-components';

export const OfferBadge = styled.div`
  position: absolute;
  top: -2px;
  right: -2px;
  width: 100px;
  height: 100px;
  background-color: #f05945;
  color: #fff;
  clip-path: polygon(0 0, 100% 0, 100% 100%);
  text-align: right;
  padding-right: 15px;
  padding-top: 10px;
`;